<div class="holder" *ngIf="!selectedCustomer">
  <h1>Müşteriler ({{ customers.length }})</h1>
  <span class="message-holder" *ngIf="message">
    {{ message }}
  </span>
  <div>
    <button
      (click)="newCustomer()"
      class="btn btn-primary"
      style="margin-bottom: 30px"
    >
      Yeni Müşteri Ekle
    </button>
    <table class="table table-hover table-bordered">
      <thead>
        <tr>
          <th>Telefon</th>
          <th>Adı Soyadı</th>
          <th>Durumu</th>
          <th>Ticari İleti</th>
          <th>Açık Rıza Onayı</th>
          <th>Ret</th>
          <th>Cinsiyet</th>
          <th>Yaş</th>
          <th>Eğitim Durumu</th>
          <th>Şehir</th>
          <th>SES Grubu</th>
          <th>Hangi Markanın Anketini Çözdü</th>
          <th>Hangi Cihazdan Çözdü</th>
          <th>Hangi Saatte Çözdü</th>
          <th>Ne Kadar Sürede Çözdü</th>
          <th>Başka Anket Çözdü Mü</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of customers">
          <td>{{ item.phone }}</td>
          <td>{{ item.name }}</td>
          <td>
            {{ item.isActive == "false" || !item.isActive ? "Pasif" : "Aktif" }}
          </td>
          <td>
            {{ item.ticariIletiAgreement ? "Sms Var" : "Sms Yok" }} -
            {{ item.ticariIletiAgreementEmail ? "Email Var" : "Email Yok" }} -
            {{ item.ticariIletiAgreementIvr ? "IVR Var" : "IVR Yok" }}
          </td>
          <td>{{ item.openConsentAgreement ? "Var" : "Yok" }}</td>
          <td>
            <ng-template [ngIf]="item.ticariIletiAgreementRejectionDate">
              Sms Ret (Tarih:
              {{
                item.ticariIletiAgreementRejectionDate
                  | date : "dd.MM.yyyy HH:mm"
              }})
            </ng-template>
            <ng-template [ngIf]="!item.ticariIletiAgreementRejectionDate">
              Sms Ret Yok
            </ng-template>
            -
            <ng-template [ngIf]="item.ticariIletiAgreementEmailRejectionDate">
              Email Ret (Tarih:
              {{
                item.ticariIletiAgreementEmailRejectionDate
                  | date : "dd.MM.yyyy HH:mm"
              }})
            </ng-template>
            <ng-template [ngIf]="!item.ticariIletiAgreementEmailRejectionDate">
              Email Ret Yok
            </ng-template>
            -
            <ng-template [ngIf]="item.ticariIletiAgreementIvrRejectionDate">
              IVR Ret (Tarih:
              {{
                item.ticariIletiAgreementIvrRejectionDate
                  | date : "dd.MM.yyyy HH:mm"
              }})
            </ng-template>
            <ng-template [ngIf]="!item.ticariIletiAgreementIvrRejectionDate">
              IVR Ret Yok
            </ng-template>
          </td>
          <td>{{ item.report?.gender || "" }}</td>
          <td>{{ item.report?.age || "" }}</td>
          <td>{{ item.report?.educationStatus || "" }}</td>
          <td>{{ item.report?.city || "" }}</td>
          <td>{{ item.report?.sesGroup || "" }}</td>
          <td>{{ item.report?.companyName || "" }}</td>
          <td>{{ item.report?.deviceInfo || "" }}</td>
          <td>{{ item.report?.createdOnString || "" }}</td>
          <td>{{ item.report?.finishTimeInSecondsString || "" }}</td>
          <td>{{ item.report?.anotherReportString || "" }}</td>
          <td>
            <div class="table-actions">
              <button
                class="btn btn-primary btn-xs"
                (click)="selectCustomer(item)"
              >
                Görüntüle
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="holder" *ngIf="selectedCustomer">
  <h1>{{ selectedCustomer.name }}</h1>
  <span class="message-holder" *ngIf="message">{{ message }}</span>
  <div class="form-control-holder">
    <label for="name">Adı</label>
    <input type="text" id="name" [(ngModel)]="selectedCustomer.name" />
  </div>
  <div class="form-control-holder">
    <label for="age">Yaşı</label>
    <input type="date" id="age" [(ngModel)]="selectedCustomer.age" />
  </div>
  <div class="form-control-holder">
    <label for="gender">Cinsiyeti</label>
    <select name="gender" id="gender" [(ngModel)]="selectedCustomer.gender">
      <option>Seçiniz</option>
      <option value="0">Kadın</option>
      <option value="1">Erkek</option>
    </select>
  </div>
  <div class="form-control-holder">
    <label for="">Email</label>
    <input type="email" [(ngModel)]="selectedCustomer.email" />
  </div>
  <div class="form-control-holder">
    <label for="">Şifre</label>
    <input type="text" [(ngModel)]="selectedCustomer.password" />
  </div>
  <div class="form-control-holder">
    <label for="gender">Aktif mi?</label>
    <select name="gender" id="gender" [(ngModel)]="selectedCustomer.isActive">
      <option value="false">Hayır</option>
      <option value="true">Evet</option>
    </select>
  </div>
  <app-button
    type="link"
    text="Kullanıcıyı Sil"
    (click)="removeCustomer()"
  ></app-button>
  <app-button text="Kullanıcıyı Kaydet" (click)="saveChanges()"></app-button>

  <hr />

  <h2>Geçmiş İşlemler</h2>

  <ul>
    <li>Kullanıcıya ait hiç işlem bulunmuyor</li>
  </ul>
</div>
