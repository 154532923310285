<div class="holder">
  <div>
    <h1>İletişim</h1>
    <p>
      Adres: Fulya Mahallesi, Yeşilçimen Sokak, Polat Tower, Bilgiyi
      Ticarileştirme Merkezi Kampüsü 34394 Şişli/İstanbul
    </p>
    <p>Telefon: <a href="tel:+90 533 379 2509">+90 533 379 2509</a></p>
    <p>
      E-Posta: <a href="mailto:info@{{ hostName }}">info&#64;{{ hostName }}</a>
    </p>
  </div>
  <div class="form-holder">
    <form action="/" (submit)="onSubmit($event)">
      <div>
        <label for="">Ad Soyad</label>
        <input type="text" name="name" required />
      </div>
      <div>
        <label for="">Eposta</label>
        <input type="email" name="email" required />
      </div>
      <div>
        <label for="">Telefon Numarası</label>
        <input type="tel" name="tel" />
      </div>
      <div>
        <label for="">Konu</label>
        <input type="text" name="title" required />
      </div>
      <div>
        <label for="">Mesaj</label>
        <textarea name="text" value="" rows="3" required> </textarea>
      </div>
      <button type="submit">Gönder</button>
      <div class="register-agreement">
        Kayıt Ol’a basarak Bilgesin
        <a href="/kullanim-kosullari" target="_blank">Kullanım Koşullarını</a>
        onayladığınız ve KVKK
        <a href="/kisisel-verilerin-korunmasi" target="_blank"
          >Gizlilik ve Çerez Politikasını</a
        >
        okuyup, anladığınızı kabul edersiniz.
      </div>
    </form>
  </div>
</div>
