import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./pages/login/login.component";
import { SelectComponent } from "./components/select/select.component";
import { InputComponent } from "./components/input/input.component";
import { ButtonComponent } from "./components/button/button.component";
import { LogoComponent } from "./components/logo/logo.component";
import { HomeComponent } from "./pages/home/home.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { CampaignDetailComponent } from "./pages/campaign-detail/campaign-detail.component";
import { SurveyComponent } from "./pages/survey/survey.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { MenuComponent } from "./components/menu/menu.component";
import { AdminComponent } from "./pages/admin/admin.component";
import { AdminNewSurweyComponent } from "./pages/admin-new-surwey/admin-new-surwey.component";
import { AdminReportsComponent } from "./pages/admin-reports/admin-reports.component";
import { AdminStatisticsComponent } from "./pages/admin-statistics/admin-statistics.component";
import { AdminCompanyInfoComponent } from "./pages/admin-company-info/admin-company-info.component";
import { EmailVerificationSuccessComponent } from "./pages/email-verification-success/email-verification-success.component";
import { RootCompaniesComponent } from "./pages/root-companies/root-companies.component";
import { RootSectorsComponent } from "./pages/root-sectors/root-sectors.component";
import { RootFormsComponent } from "./pages/root-forms/root-forms.component";
import { RootCustomersComponent } from "./pages/root-customers/root-customers.component";
import { RootQuestionsComponent } from "./pages/root-questions/root-questions.component";
import { FaydaliBilgilerComponent } from "./pages/faydali-bilgiler/faydali-bilgiler.component";
import { GizlilikPolitikasiComponent } from "./pages/gizlilik-politikasi/gizlilik-politikasi.component";
import { HakkimizdaComponent } from "./pages/hakkimizda/hakkimizda.component";
import { IletisimComponent } from "./pages/iletisim/iletisim.component";
import { IsOrtagimizOlmakIsterMisinizComponent } from "./pages/is-ortagimiz-olmak-ister-misiniz/is-ortagimiz-olmak-ister-misiniz.component";
import { KariyerComponent } from "./pages/kariyer/kariyer.component";
import { KisiselVerilerinKorunmasiComponent } from "./pages/kisisel-verilerin-korunmasi/kisisel-verilerin-korunmasi.component";
import { KullanimKosullariComponent } from "./pages/kullanim-kosullari/kullanim-kosullari.component";
import { MusteriKisiselVerileriAydinlatmaMetniComponent } from "./pages/musteri-kisisel-verileri-aydinlatma-metni/musteri-kisisel-verileri-aydinlatma-metni.component";
import { NasilKazanirimComponent } from "./pages/nasil-kazanirim/nasil-kazanirim.component";
import { NedenBilgesinComponent } from "./pages/neden-bilgesin/neden-bilgesin.component";
import { AdminQuestionsComponent } from "./pages/admin-questions/admin-questions.component";
import { SizeOzelComponent } from "./pages/size-ozel/size-ozel.component";
import { FacebookPrivacyComponent } from "./pages/facebook-privacy/facebook-privacy";

import { AgChartsAngularModule } from "ag-charts-angular";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { ForgattenComponent } from "./pages/login/forgatten/forgatten.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { AdminVerifyDiscountCodeComponent } from "./pages/admin-verify-discount-code/admin-verify-discount-code.component";
import { UserEarningsComponent } from "./pages/user-earnings/user-earnings.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { BizdenHaberlerComponent } from "./pages/bizden-haberler/bizden-haberler.component";

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthInterceptorService } from "./services/auth-interceptor.service";
import { APIInterceptorService } from "./services/apiinterceptor.service";
import { UnderConstructionComponent } from "./pages/under-construction/under-construction.component";
import { AdminSurveyDiscountCodesComponent } from "./pages/admin-survey-discount-codes/admin-survey-discount-codes.component";
import { RootBlogsComponent } from "./pages/root-blogs/root-blogs.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogDetailComponent } from "./pages/blog-detail/blog-detail.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { RouterModule } from "@angular/router";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { AdminSurveySmsTextComponent } from "./pages/admin-survey-sms-text/admin-survey-sms-text.component";
import { RootHomeSurveySortingComponent } from "./pages/root-home-survey-sorting/root-home-survey-sorting.component";
import { RootUserReportsComponent } from "./pages/root-user-reports/root-user-reports.component";
import { AcikRizaComponent } from "./pages/acik-riza/acik-riza.component";
import localeTr from "@angular/common/locales/tr";
import { registerLocaleData } from "@angular/common";
import { RootCompanyFormCouponsComponent } from "./pages/root-company-form-coupons/root-company-form-coupons.component";
import { CustomerReviewsComponent } from "./components/customer-reviews/customer-reviews.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { PasswordInputComponent } from "./components/password-input/password-input.component";
import { HomeVideoModalComponent } from "./components/home-video-modal/home-video-modal.component";
import { AdminUserInfosComponent } from "./pages/admin-user-infos/admin-user-infos.component";
import { ForBrandsComponent } from "./pages/for-brands/for-brands.component";
import { SearchModalComponent } from "./components/search-modal/search-modal.component";
import { RootSearchLogsComponent } from './pages/root-search-logs/root-search-logs.component';
import { ProductLinksModalComponent } from './components/product-links-modal/product-links-modal.component';
import { AdminPazanovaComponent } from './pages/admin-pazanova/admin-pazanova.component';
import { SurveyThanksComponent } from './pages/survey-thanks/survey-thanks.component';
import { YonetimEkibimizComponent } from './pages/yonetim-ekibimiz/yonetim-ekibimiz.component';
import { TicariIletiComponent } from './pages/ticari-ileti/ticari-ileti.component';
import { PazarlamaAcikRizaComponent } from './pages/pazarlama-acik-riza/pazarlama-acik-riza.component';

registerLocaleData(localeTr, "tr");

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SelectComponent,
    InputComponent,
    ButtonComponent,
    LogoComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    CampaignDetailComponent,
    SurveyComponent,
    BreadcrumbComponent,
    MenuComponent,
    SizeOzelComponent,
    AdminComponent,
    AdminNewSurweyComponent,
    AdminStatisticsComponent,
    AdminQuestionsComponent,
    AdminCompanyInfoComponent,
    AdminReportsComponent,
    EmailVerificationSuccessComponent,
    RootCompaniesComponent,
    RootSectorsComponent,
    RootFormsComponent,
    RootCustomersComponent,
    RootQuestionsComponent,
    FaydaliBilgilerComponent,
    GizlilikPolitikasiComponent,
    HakkimizdaComponent,
    IletisimComponent,
    IsOrtagimizOlmakIsterMisinizComponent,
    KariyerComponent,
    KisiselVerilerinKorunmasiComponent,
    KullanimKosullariComponent,
    MusteriKisiselVerileriAydinlatmaMetniComponent,
    NasilKazanirimComponent,
    NedenBilgesinComponent,
    FacebookPrivacyComponent,
    ForgattenComponent,
    LoaderComponent,
    AdminVerifyDiscountCodeComponent,
    UserEarningsComponent,
    ResetPasswordComponent,
    BizdenHaberlerComponent,
    UnderConstructionComponent,
    AdminSurveyDiscountCodesComponent,
    RootBlogsComponent,
    BlogComponent,
    BlogDetailComponent,
    PaginationComponent,
    AdminSurveySmsTextComponent,
    RootHomeSurveySortingComponent,
    RootUserReportsComponent,
    AcikRizaComponent,
    RootCompanyFormCouponsComponent,
    CustomerReviewsComponent,
    PasswordInputComponent,
    HomeVideoModalComponent,
    AdminUserInfosComponent,
    ForBrandsComponent,
    SearchModalComponent,
    RootSearchLogsComponent,
    ProductLinksModalComponent,
    AdminPazanovaComponent,
    SurveyThanksComponent,
    YonetimEkibimizComponent,
    TicariIletiComponent,
    PazarlamaAcikRizaComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    AgChartsAngularModule,
    NgxDaterangepickerMd.forRoot(),
    RouterModule,
    CKEditorModule,
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: "tr" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
