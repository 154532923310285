<div class="holder">
  <h1>
    6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU KAPSAMINDA MÜŞTERİ/SON
    KULLANICI/BAŞVURUCU/ÇEVRİMİÇİ ZİYARETÇİ İÇİN KİŞİSEL VERİLERİN İŞLENMESİNE
    İLİŞKİN AYDINLATMA METNİ
  </h1>
  <p>
    İşbu Aydınlatma Metni, yerel mevzuatımızda yer alan 6698 sayılı Kişisel
    Verilerin Korunması Kanunu (“KVKK”) ile Avrupa Birliği Genel Veri Koruma
    Tüzüğü (“GDPR”) uyarınca, Bilgesin. com (“BİLGESİN”) müşterileri ve son
    kullanıcılarına ait kişisel verilerin işlenmesi, saklanması, aktarılması ve
    imhasına ilişkin usul ve esaslar hakkında veri sahiplerinin aydınlatılması
    amacıyla hazırlanmıştır.
  </p>
  <br /><br />
  <p>
    BİLGESİN, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) ve Avrupa
    Birliği Genel Veri Koruma Tüzüğü (“GDPR”) uyarınca, müşteri/son
    kullanıcı/çevrimiçi ziyaretçi veri güvenliği göz önünde bulundurularak, özel
    hayatın gizliliği ve temel hak ve özgürlüklerin korunmasına önem
    verilmektedir. Bu doğrultuda, kişisel verilerinizin işlenme amaçları, hukuki
    nedenleri, toplanma yöntemi, kimlere aktarılabileceği ve gerek KVKK ve
    gerekse GDPR (yurtdışına aktarılan veriler bakımından) kapsamında size
    tanınan haklara ilişkin olarak sizleri bilgilendirmek istiyoruz.
  </p>
  <br /><br />
  <b>1. Hangi Kişisel Verileri İşliyoruz?</b><br />
  <p>
    Hastalarımıza sağlık hizmeti sunmak için çeşitli kişisel verileri ile özel
    nitelikli kişisel verileri, KVKK m.4 ve GDPR m.5 çerçevesinde, KVKK 5. ve 6.
    maddeleri ile GDPR 6. ve 9. maddelerinde belirtilen Kişisel Veri İşleme
    Şartları ve Amaçları doğrultusunda ve işbu Aydınlatma Metninde yer alan
    amaçlar kapsamında kalmak üzere; hukuka uygun şekilde, işleme amacıyla
    bağlantılı, sınırlı ve ölçülü olarak ve veri güvenliğinin sağlanması
    amacıyla mevzuatta öngörülen her türlü teknik ve idari tedbirler alınarak
    işlenecektir.
  </p>
  <br /><br />
  <p>
    <b>a-Kimlik Bilgileriniz:</b> Adınız, soyadınız, T.C. Kimlik Numaranız
    ve/veya Pasaport Numaranız ve/veya Geçici T.C. Kimlik Numaranız, BİLGESİN
    tarafından size özgülenebilecek müşteri numaranız, doğum tarihiniz, medeni
    haliniz, cinsiyetiniz ve sizi tanımlayabilen diğer kimlik verileriniz.
    <br />
    <b>b-İletişim Bilgileriniz:</b> Adresiniz (ikametgah/iş yeri), telefon
    numaranız (bildirdiğiniz ev/iş yeri sabit ve/veya mobil telefon
    numaralarınız), elektronik posta adresiniz
  </p>
  <br /><br />
  <b>2. Kişisel Verilerinizi Hangi Amaçlarla İşliyor ve Aktarıyoruz?</b><br />
  <p>
    Yukarıda yer alan Kişisel ve Özel Nitelikli Kişisel Verileriniz; başta
    hukuki yükümlülüklerimizi yerine getirmek, şirketimiz faaliyetlerinin
    planlanması ve yönetimi; talep etmeniz takdir halinde tarafınıza sunulan ve
    sunulacak hizmetlere ilişkin size bilgi sağlama, BİLGESİN’in iç işleyişini
    planlanma ve yönetme, hizmetlerimizi geliştirmek amacıyla analiz yapma,
    kimliğinizin doğrulanması, suistimal ve yetkisiz işlemlerin izlenmesi ve
    engellenmesi, teknolojik ve bilimsel araştırma yapılması, tarafımızca
    sunulan hizmetlere ilişkin olarak her türlü soru ve şikâyetinize cevap
    verebilme; işbirliği halinde olduğumuz anlaşmalı kurumlarla size sunulan
    hizmetlere ilişkin finansal mutabakat sağlanması; uygulanan mevzuat uyarınca
    gerekli kamu kurum ve kuruluşlarının taleplerine yanıt verme ve benzeri
    amaçlarla işlenecek, kaydedilecek, sınıflandırılacak ve kamu güvenliğine
    ilişkin hususlarda ve doğabilecek hukuki uyuşmazlıklarda, talep halinde ve
    mevzuat gereği ilgili yargı mercilerine ve ilgili kamu görevlilerine bilgi
    verebilmek için; gerektiğinde yukarıda belirttiğimiz amaçlar dahilinde
    yetkili kamu kurum ve kuruluşlarına, iş birliği içerisinde olduğumuz yurt
    içi ve yurtdışı şirket ve kuruluşlara, hukuk danışmanlarımız ve anlaşmalı
    olduğumuz avukatlık bürolarına, muhasebeci ve mali müşavirlere, bilgi işlem
    personeline, hizmet ve malzeme tedarikçisine ve açık rızanızın olması
    halinde yurtiçi ve yurtdışında bulunan, işbirliği halinde bulunduğumuz
    bilimsel, teknolojik saygın kuruluşlar nezdinde yürütmekte olduğumuz
    bilimsel ve teknolojik çalışmalarımız esnasında gerekli anonimleştirme
    yapılarak (verilerin anonim hale getirilmesi, kişisel verilerin maskeleme,
    değişken çıkartma, genelleştirme vb. tekniklerle başka verilerle
    eşleştirilse dahi hiçbir surette kimliği belirli veya belirlenebilir bir
    gerçek kişiyle ilişkilendirilemeyecek hale getirilmesi işlemini ifade
    etmektedir) aktarılabilecektir.
  </p>
  <br /><br />
  <b
    >3. Kişisel Verilerinizin Toplanma Yöntemleri Ve Bunlara İlişkin Hukuki
    Sebepler Nelerdir?</b
  ><br />
  <p>
    Yukarıda belirtilen kişisel verileriniz 6698 sayılı Kanun’un 5. ve 6.
    maddelerinde belirtilen (i) kanunlarda açıkça öngörülmüş olması, (ii) bir
    sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla,
    sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması,
    (iii) Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan
    veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir
    başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması,
    (iv) veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için
    zorunlu olması, (v) ilgili kişinin temel hak ve özgürlüklerine zarar
    vermemek kaydıyla veri sorumlusunun meşru menfaati için zorunlu olması, (vi)
    kamu sağlığının korunması, koruyucu hekimlik, tıbbî teşhis, tedavi ve bakım
    hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması
    ve yönetimi sebepleriyle (vii) veya açık rızanıza dayanılarak işbu
    Aydınlatma Metninde belirtilen amaçlar kapsamında;
  </p>
  <br /><br />
  <p>
    <b>a)</b> Hukuka ve dürüstlük kurallarına uygun olmak,<br />
    <b>b)</b> Doğru ve gerektiğinde güncel olmak,<br />
    <b>c)</b> Belirli, açık ve meşru amaçlar ile uyumlu olmak,<br />
    <b>d)</b> İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olmak,<br />
    <b>e)</b> İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli
    olan süre kadar muhafaza edilmek üzere otomatik olan (ör., kamera kayıtları)
    ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik
    olmayan yollarla (ör., tarafınızdan doldurulan formlar) işlenecek,
    kaydedilecek, depolanacak, muhafaza edilecek, sınıflandırılacak,
    aktarılabilecek ve saklama süresi sonunda imha edilecektir.<br />
  </p>

  <b
    >4. Kişisel Verilerin İşlenmesine Dair Haklarınız Nelerdir ve Bu Haklara
    Nasıl Erişebilirsiniz?</b
  ><br />
  <p>
    Anayasa’nın 20. maddesinde herkesin, kendisiyle ilgili kişisel veriler
    hakkında bilgilendirilme hakkına sahip olduğu ortaya konulmuş olmakla,
    kişisel veri sahibinin 6698 Sayılı Kişisel Verilerin Korunması Kanunu
    kapsamındaki hakları, KVKK’nın 11. maddesinde sayılmıştır. KVKK kapsamındaki
    haklarınıza ilişkin taleplerinizi “Veri Sorumlusuna Başvuru Usul ve Esasları
    Hakkında Tebliğ” Madde 5’e BİLGESİN’e yazılı olarak veya güvenli elektronik
    imza, mobil imza ya da ilgili kişi tarafından veri sorumlusuna daha önce
    bildirilen ve veri sorumlusunun sisteminde kayıtlı bulunan elektronik posta
    adresini kullanmak suretiyle iletebilirsiniz.
  </p>
  <br /><br />
  <p>
    <b>Veri Sorumlusu:</b> BİLGESİN <br />
    <b>Adres:</b> Fulya Mah. Yeşilçimen Sok. Polat Tower Bağımsız Bölüm 430,
    34394 Şişli/İstanbul<br />
    <b>Mail:</b> info&#64;bilgesin.com<br />
  </p>
</div>
