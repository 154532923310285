import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-ticari-ileti",
  templateUrl: "./ticari-ileti.component.html",
  styleUrls: ["./ticari-ileti.component.scss"],
})
export class TicariIletiComponent implements OnInit {
  hostName = environment.hostName();

  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title: "Elektronik Ticari İleti Bilgilendirme Metni - Bilgesin",
      description: "Elektronik Ticari İleti Bilgilendirme Metni",
    });
  }
}
