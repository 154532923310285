import { Component, OnInit } from "@angular/core";
import { scrollToTop } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-pazarlama-acik-riza",
  templateUrl: "./pazarlama-acik-riza.component.html",
  styleUrls: ["./pazarlama-acik-riza.component.scss"],
})
export class PazarlamaAcikRizaComponent implements OnInit {
  hostName = environment.hostName();

  constructor(private metaService: MetaService) {}

  ngOnInit(): void {
    scrollToTop();

    this.metaService.set({
      title: "Bilgesin Pazarlama Faaliyetleri Açık Rıza Metni - Bilgesin",
      description: "Bilgesin Pazarlama Faaliyetleri Açık Rıza Metni",
    });
  }
}
