<div class="holder">
  <h1>Elektronik Ticari İleti Bilgilendirme Metni</h1>
  <p>
    Tarafınıza etkinlik ve tanıtım içeriklerinin gönderilmesini ve/veya yenilik
    ve kampanyalardan haberdar olmayı talep etmeniz halinde; Uygulama’da yer
    alan Anketleri doldurduğunuzda veya Hesap Kayıt Bilgileri altında işbu metni
    okuduğunuzu ve ticari ileti almak istediğinizi belirtmeniz; iletileri almaya
    onay vermeniz gerekmektedir. Bu kapsamda bizimle paylaştığınız telefon
    numarası veya e-posta adresi aracılığıyla sizlerle iletişime geçebilir,
    etkinlik ve tanıtım içeriklerimizi gönderebiliriz.
    <br /><br />
    Ayrıca indirim, promosyon hediye kodu gibi anlaşmalı olduğumuz markalara
    ilişkin Anket doldurduğunuzda ilgili anlaşmalı markalarımız da tarafınızla
    iletişime geçebilir, etkinlik ve tanıtım içeriklerini gönderebilir.
    <br /><br />
    Dilediğiniz an, hiçbir gerekçe göstermeksizin haber, bilgi ve tanıtım
    içeriklerimize ilişkin elektronik ileti almayı sonlandırma hakkına
    sahipsiniz.
    <br /><br />
    Bu haklarınızı ücretsiz olarak, size gönderilen herhangi bir elektronik
    ticari iletinin altında bulunan linke tıklayarak ve/veya iletişim numarasını
    arayarak kullanabileceğiniz gibi taleplerinizi
    <a href="mailto:info@{{ hostName }}">info&#64;{{ hostName }}</a> adresine
    göndereceğiniz bir e-posta aracılığıyla da tarafımıza iletebilirsiniz.
    Sonlandırma talebinizin Şirket’e ulaşmasını takip eden en kısa süre
    içerisinde elektronik ileti gönderimi durdurulacaktır.
  </p>
</div>
