<div class="holder">
  <h1>Bilgesin Pazarlama Faaliyetleri Açık Rıza Metni</h1>
  <p>
    <b>Bilgesin Bilişim Teknolojileri Anonim Şirketi</b> tarafından; pazarlama
    ve tanıtım faaliyetlerinin planlanması, pazarlama segmentasyon
    çalışmalarının yürütülmesi, tarafıma özelleştirilmiş kampanyaların
    oluşturulması ve sair reklam tanıtım faaliyetlerinini yürütülmesi
    amaçlarıyla, e-posta ve telefon numarası, cinsiyet, yaş aralığı, çalışma
    durumu ve alışveriş tercihlerine ilişkin ilgili markaya özgü anket
    aracılığıyla paylaştığım kişisel verilerimin
    <b>“Bilgesin KVKK Gizlilik ve Çerez Politikası”</b> kapsamında işlenmesini
    ve anlaşmalı markalara pazarlama ve tanıtım faaliyetlerinin planlanması,
    pazarlama segmentasyon çalışmalarının yürütülmesi, tarafıma özelleştirilmiş
    kampanyaların oluşturulması ve sair reklam tanıtım faaliyetlerinini
    yürütülmesi amaçlarıyla aktarılmasına onay veriyorum.
  </p>
</div>
