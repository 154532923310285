import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ReplaySubject } from "rxjs";
import { environment } from "src/environments/environment";
import { pazanovaCompanyId } from "../helpers/helpers";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public user: ReplaySubject<any> = new ReplaySubject();

  constructor(private router: Router, private http: HttpClient) {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    if (user.id) {
      this.user.next(user);
    }
  }

  async login({
    phone = null,
    email = null,
    password,
    loginState,
    isLoginPage = false,
  }) {
    try {
      const res: any = await this.http
        .post(loginState === "phone" ? "/loginWithPhone" : "/login", {
          email,
          phone,
          password,
        })
        .toPromise();

      const { status, user, token, code, message } = res.data;
      if (status) {
        this.saveLoginResponse({
          token,
          user,
        });
        if (isLoginPage) {
          if (user.isAdmin) {
            if (this.isPazanova()) {
              this.router.navigate(["/admin/pazanova"]);
            } else {
              this.router.navigate(["/admin/new-survey"]);
            }
          } else if (user.isRoot) {
            this.router.navigate(["/admin/companies"]);
          } else {
            this.router.navigate(["/"]);
          }
        }

        return true;
      } else {
        if (code === "NO_USER") {
          return "NO_USER";
        } else {
          alert(message);
        }
      }
    } catch (ex) {
      alert(ex.message);
    }

    return false;
  }

  async register({
    email,
    phone,
    registerState,
    openConsentAgreement,
    ticariIletiAgreement,
  }) {
    const res: any = await this.http
      .post("/register", {
        email,
        phone,
        isPhone: registerState === "phone",
        openConsentAgreement,
        ticariIletiAgreement,
      })
      .toPromise();
    if (res.data) {
      if (registerState === "phone") {
        alert(
          "Kayıt işlemi başarılı, telefonunuza gelen kodu şifre alanına girerek giriş yapabilirsiniz."
        );
      } else {
        alert(
          "Kayıt işlemi başarılı, email adresinize gelen kodu şifre alanına girerek giriş yapabilirsiniz."
        );
      }
      return "login";
    } else {
      alert(res.message);
    }
    return null;
  }

  async underConstructionRootLogin({ email, password }) {
    try {
      const res: any = await this.http
        .post("/login", {
          email,
          password,
        })
        .toPromise();
      const { status, user, token, code, message } = res.data;
      if (status) {
        if (user.isRoot) {
          localStorage.setItem("rootAccessGranted", "true");
          this.router.navigate(["/"]);
        } else {
          alert("Sayfayı görmek için yetkiniz bulunumamaktadır!");
        }
      } else {
        alert(message);
      }
    } catch (ex) {
      alert(ex.message);
    }
  }

  saveLoginResponse(res) {
    localStorage.setItem("user", JSON.stringify(res.user));
    localStorage.setItem("token", res.token);
    this.user.next(res.user);
  }

  getUser() {
    const userString = localStorage.getItem("user");
    return userString ? JSON.parse(userString) : null;
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    window.location.reload();
  }

  controlUnderConstruction() {
    return;

    if (environment.hostName() !== "bilgesin.com") {
      return;
    }

    if (this.router.url === "/under-construction") {
      return;
    }

    const rootAccessGranted = localStorage.getItem("rootAccessGranted");
    if (!rootAccessGranted) {
      this.router.navigate(["/under-construction"]);
    }
  }

  isPazanova() {
    return this.getUser()?.companyId === pazanovaCompanyId;
  }
}
