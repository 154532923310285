<div class="holder">
  <h1>Kullanım Koşulları</h1>
  <p>
    NOT: BU KULLANIM KOŞULLARI YALNIZCA TÜRKİYE’DE BULUNAN KULLANICILARA
    İLİŞKİNDİR. WEBSİTESİNİ TÜRKİYE DIŞINDA KULLANMANIZ HALİNDE BULUNDUĞUNUZ
    LOKASYONA GÖRE FARKLI KULLANIM KOŞULLARI GEÇERLİ OLACAKTIR. WEBSİTESİ
    BULUNDUĞUNUZ LOKASYONA GÖRE SİZİN İÇİN GEÇERLİ OLACAK KULLANIM KOŞULLARINI
    GÖSTERECEKTİR.
  </p>
  <b>1. Tanımlar</b>
  <br />
  <br />
  <p>Bilgesin: Bilgesin Bilişim.</p>
  <p>
    Müşteri: İnternet sitesi üzerinden mal veya hizmetlere ilişkin talep
    oluşturan kişi.
  </p>
  <p>Taraf(lar): Müşteri ve/veya Bilgesin.</p>
  <p>Koşullar: Bu kullanım koşullarının tamamı.</p>
  <p>
    Uygulama: Bilgesin’e ait mobil ve diğer internet bağlantısı olan cihazlarda
    kullanılabilen Bilgesin’in sunduğu ürünlerin/hizmetlerin sipariş
    edilebildiği tüm fikri ve sınai hakları Bilgesin’e ait uygulama. Sözleşme:
    Taraflar arasındaki Ön bilgilendirme Formu ve bu formda yer alan bilgiler
    uyarınca kurulan mesafeli satış sözleşmesi.
  </p>
  <p>
    Ürün(ler): Bilgesin’in hizmet verdiği bölgelerde, İnternet Sitesi’nde yer
    alan seçenekler arasından, Müşteri tarafından seçilen ve Bilgesin
    tarafından, Müşteri’ye sunulan hizmet veya mal.
  </p>
  <p>
    İnternet Sitesi: Bilgesin’e ait mobil ve diğer internet bağlantısı olan
    cihazlardan erişilebilen Bilgesin’in sunduğu ürünlerin/hizmetlerin sipariş
    edilebildiği tüm fikri ve sınai hakları Bilgesin’e ait www.{{ hostName }}
    internet sitesi
  </p>
  <b>2. Başvuru ve Üyelik </b>
  <br />
  <br />
  <p>
    2.1. Kullanıcılar, İnternet Sitesi’nde kayıt için gerekli olan bölümleri
    doldurup, GSM numarasını veya eposta adresini onayladıktan sonra, şifresini
    girerek, işbu koşullar dahilinde İnternet Sitesi’ni kullanmaya başlayabilir.
  </p>
  <p>
    2.2. Müşteri, başvuru esnasında verdiği tüm bilgilerin daima ve her bakımdan
    eksiksiz, gerçeğe uygun ve güncel olduğunu kabul eder. Müşteri, bilgilerini
    her zaman İnternet Sitesi aracılığıyla güncelleyebilir.
  </p>
  <b>3. İnternet Sitesi’nin Kullanımı</b><br /><br />
  <p>
    3.1. Müşteri, Sipariş’e ilişkin tüm şikayetlerini Bilgesin’in müşteri
    hizmetlerine info&#64;{{ hostName }} e-posta adresi üzerinden aktarabilir.
  </p>
  <p>
    3.2. Bilgesin’in sunduğu hizmetten yararlanan Müşteri, İnternet Sitesi’ni
    hukuka ve amacına uygun şekilde kullanmayı ve İnternet Sitesi dahilinde
    yaptığı her işlem ve eylemdeki hukuki sorumluluğunun kendisine ait olduğunu
    kabul eder. Müşteri’nin İnternet Sitesi aracılığıyla ve/veya dahilinde,
    Koşullar’a ve hukuka aykırı olarak gerçekleştirdiği herhangi bir işlem,
    eylem ve/veya faaliyetleri nedeniyle Bilgesin doğrudan veya dolaylı olarak
    hiçbir şekilde sorumlu tutulamaz.
  </p>
  <p>
    3.3. Bilgesin, Müşteri’den kaynaklanan teknik aksaklıklar sebebiyle
    hizmetten faydalanılamaması durumunda yükümlülüklerini ifa edememesi
    nedeniyle sorumlu tutulamayacak ve kendisinden herhangi bir nam altında
    herhangi bir talepte bulunulamayacaktır.
  </p>
  <p>
    3.4 Müşteri, İnternet Sitesi üzerinde alınan tüm hizmetlerim, yapılan
    alışverişlerin kişisel kullanım ve tüketim amaçlı olarak son kullanıcıya
    yapıldığını, tekrar satış amaçlı olmadığını kabul eder.
  </p>
  <p>
    3.5 İnternet Sitesi’nde paylaşılan içeriklere/yorumlara ilişkin olarak;
    içeriklerin yorumların doğruluk, kalite, hukuka uygunluk, yerindelik,
    güvenilirlik, nitelik başta olmak üzere bütün özelliklerinden yalnızca
    Müşteri sorumludur.
  </p>
  <p>
    3.6 Yorumların hukuka uygun olup olmadığını veya bu Kullanım Koşulları’nı
    ihlal edip etmediğini (yasadışı içeriklerin tarafımıza bildirildiği durumlar
    gibi) belirlemek ve yasal yükümlülüklere uyum sağlamak adına, Bilgesin
    yorumları inceleyebilecektir. Ayrıca yasaları ve/veya bu işbu Koşulları
    ihlal ettiği düşünülen içerikler, Bilgesin tarafından değiştirilebilir,
    silinebilir, bu içeriklere yönelik erişim/görüntüleme engellenebilir.
    Bilgesin’in yorumları yayınlamama hakkı saklıdır.
  </p>
  <p>
    3.28. Müşteriler, İnternet Sitesi’nde gerçekleştireceği işlemlerde aşağıdaki
    kapsamda yorum, paylaşım ve diğer içerikler paylaşamazlar:
  </p>
  <ul>
    <li>Sakıncalı veya yasalara aykırı her türlü içerik.</li>
    <li>
      Diğer kullanıcılar ve/veya üçüncü kişiler için zarar verici, hakaret
      içerikli, kişilik haklarını zedeleyen ve/veya kırıcı içerikler.
    </li>
    <li>Herhangi bir kişi veya kurumu tehdit eden içerikler.</li>
    <li>Herhangi bir kişiye yönelik saldırgan veya zarar verici içerikler</li>
    <li>Kullanıcıların genelini rahatsız eden/edebilecek olan içerikler.</li>
    <li>
      Tek bir kullanıcı tarafından sürekli olarak paylaşılan, konu ve sayfa
      içeriği ile alakasız içerikler.
    </li>
    <li>
      Bir veya birden çok kullanıcı tarafından paylaşılan birbirinin aynısı
      içerikler (spam).
    </li>
    <li>Bir başka marka veya kurum adına reklam içerikleri.</li>
    <li>
      Yasa dışı girişimleri destekleyen ve teşvik eden her türlü yorum, bağlantı
      ve içerikler.
    </li>
    <li>
      Herhangi bir üçüncü şahıs veya kurumun haklarını çiğneyen her türlü
      içerik.
    </li>
  </ul>
  <br />
  <p>
    3.7 İnternet Sitesi’nde sağlanan her türlü içerikten/yorumdan Müşteri
    münhasıran sorumludur. Paylaşılan herhangi bir Müşteri içeriğinin/yorumunun
    (i) yasalara uygun olduğunu; (ii) üçüncü şahısların fikri mülkiyet hakları
    dahil olmak üzere herhangi bir hakkını ihlal etmediğini; ve (iii) içeriği
    sağlamak için gerekli tüm haklara ve yetkilere sahip olduğunuzu beyan
    edersiniz.
  </p>
  <p>4. Sona Erme</p>
  <p>4.1. Müşteri, İnternet Sitesi kullanımına istediği zaman son verebilir.</p>
  <p>
    4.2. Müşteri’nin işbu belirtilen Koşullar’a, ilgili mevzuata veya iyi
    niyet/ahlak kurallarına aykırı davrandığının tespiti halinde, Bilgesin
    Müşteri’nin üyeliğini askıya alma veya İnternet Sitesi’nden/Uygulama’dan
    tamamen çıkarma hakkını saklı tutar.
  </p>
  <p>5. Kişisel Verilerin Korunması</p>
  <p>
    5.1. Bilgesin, kişisel verilerinizin korunmasına yüksek önem vermektedir.
    Kişisel verilerinize ilişkin düzenlemelere erişmek için aşağıdaki
    bağlantılara tıklayabilirsiniz:
  </p>
  <p>Kişisel Verilerinizin Korunmasına İlişkin Aydınlatma Metni</p>
  <p>Müşteri Kişisel Verilerinin Korunması Politikası</p>
  <p>
    5.2. Müşteri, İnternet Sitesi/Uygulama’da oluşturulmuş her bir kullanıcı
    için yalnızca bir adet GSM numarası ve eposta adresi tanımlanabileceğini,
    tanımlanan GSM numarasının ve eposta adresinin değiştirilemeyeceğini, yeni
    bir GSM numarasının ve eposta adresinin ancak İnternet Sitesi/Uygulama
    üzerinde yeni bir kullanıcı oluşturulması halinde tanımlatılabileceğini,
    kayıtlı GSM numarası ve eposta adresi ile yapacağı her türlü işlemden bizzat
    kendisinin sorumlu olduğunu; kabul, beyan ve taahhüt eder.
  </p>
  <p>6. Fikri Mülkiyet Hakları</p>
  <p>
    6.1. Bilgesin'in; Bilgesin hizmetleri, fikri mülkiyet hakları, Bilgesin
    ticari markaları, Bilgesin ticari görünümü veya İnternet Sitesi’nin tasarım,
    metin, imge, html kodu ve diğer kodlar da dahil fakat bunlarla sınırlı
    olmamak kaydıyla, tüm unsurları, her tür ayni ve şahsi hakları, ticari bilgi
    ve birikim’e yönelik tüm hakları münhasıran Bilgesin’e aittir.
  </p>
  <p>
    6.2. Müşteri, Bilgesin hizmetlerini ve Bilgesin'in yukarıda sayılan
    çalışmalarını ticari amaçla kullanamaz, çoğaltamaz, dağıtamaz veya bunlardan
    türetilmiş çalışmalar yapamaz veya hazırlayamaz.
  </p>
  <p>
    6.3. Müşteri, İnternet Sitesi dahilinde bulunan, Bilgesin ve/veya başka bir
    üçüncü kişilere ait resimleri, metinleri, görsel ve işitsel imgeleri, video
    kliplerini, dosyaları, veri tabanlarını, katalogları ve listeleri
    çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini kabul
    eder.
  </p>
  <p>7. Değişiklikler</p>
  <p>
    Bilgesin, tamamen kendi takdirine bağlı ve tek taraflı olarak, işbu
    Koşullar’ı, uygun göreceği herhangi bir zamanda, İnternet Sitesi üzerinden
    ilan ederek değiştirebilir.
  </p>
  <p>8. Mücbir Sebepler</p>
  <p>
    Hava muhalefetleri, ulaşım kesintisi, altyapı ve internet arızaları, yangın,
    deprem, sel baskını, diğer doğal afetler ile salgın hastalık gibi olağanüstü
    olayları, kargaşa, yaygın şiddet hareketleri, grev veya resmi makamların
    düzenlemeleri dahil ancak bunlarla sınırlı olmaksızın yükümlülüklerini
    yerine getirmesini tarafsız olarak engelleyebilecek veya geciktirebilecek
    nitelikteki durumlarda Bilgesin, işbu Koşullar ile belirlenen edimlerinden
    herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle sorumlu
    tutulamaz.
  </p>
  <p>9. Uygulanacak Hukuk Ve Yetki</p>
  <p>
    9.1. İşbu Koşullar Türkiye Cumhuriyeti yasalarına tabi olacak ve onlara göre
    yorumlanacaktır.
  </p>
  <p>
    9.2. Taraflar, Koşullar’dan doğan ihtilaflarda mevzuat çerçevesinde
    belirlenen parasal sınırlar dahilinde Müşteri ve Bilgesin’in kayıtlı
    adresinin bulunduğu yerdeki tüketici hakem heyetlerinin, parasal sınırları
    aşan uyuşmazlıklarda Müşteri’nin ve Bilgesin’in bulunduğu yerdeki tüketici
    mahkemelerinin yetkili olacağını kabul etmiştir.
  </p>
  <p>
    9.3. Uyuşmazlığın tüketici uyuşmazlığı olmaması halinde, İstanbul Merkez
    (Çağlayan) Mahkemeleri ve İcra Daireleri yetkili olacaktır.
  </p>
  <p>10. Delil Sözleşmesi</p>
  <p>
    Taraflar, Koşullar’dan doğabilecek her türlü ihtilafta Bilgesin’in resmi
    defter ve ticari kayıtlarıyla, veritabanında ve sunucularında tuttuğu
    elektronik bilgilerin, bilgisayar ve ses kayıtlarının, delil
    teşkiledeceğini, bu maddenin Hukuk Muhakemeleri Kanunu’nun 193. maddesi
    anlamında delil sözleşmesi niteliğinde olduğunu kabul eder.
  </p>
  <p>11. Yürürlük</p>
  <p>
    Müşteri İnternet Sitesi üzerinden üyelik oluşturması ve/veya İnternet
    Sitesi’nin kullanması esnasında işbu Koşullar’a tabi olacağını kabul eder.
  </p>
</div>

<div>
  Meta Platform Terms 1. Introduction a. Our Platform is the set of APIs, SDKs,
  tools, plugins, code, technology, content, and services that enables others,
  including app developers and website operators, to develop functionality,
  retrieve data from Meta and any other Meta Products, or provide data to us. b.
  To use Platform (including to Process any Platform Data), you agree to these
  Platform Terms (“Terms”), as well as all other applicable terms and policies.
  This may include the Meta Terms of Service, the Instagram Terms of Use, the
  Meta Commercial Terms, the Business Tools Terms, and any Meta Product terms
  that are applicable. c. You must also comply with the applicable requirements
  in our Developer Policies and those made available on our Developer Site,
  including in our Documentation collectively, the “Developer Docs”) d. These
  Terms will start on the earlier of the date you accept them or otherwise start
  accessing or using Platform, and will continue until you stop accessing and
  using Platform, unless ended earlier as described below. If you are accepting
  these Terms or accessing or using Platform on behalf of an entity, you
  represent and warrant that you have the authority to bind such entity to these
  Terms and you agree on behalf of such entity to be bound by these Terms (and
  for clarity, all other references to “you” in these Terms refer to such
  entity). For clarity, these Terms updated and replaced the Facebook Platform
  Policy and the Instagram Platform Policy and any references in existing terms,
  policies, or agreements to the “Facebook Platform Policy,” “Instagram Platform
  Policy,” or “Platform Policy” shall now mean these Terms. e. If you fail to
  comply with these Terms or any other applicable terms or policies, we may
  suspend or terminate your App or account, as described below. f. Capitalized
  terms not otherwise defined herein (including in Section 12 (“Glossary”) have
  the meaning given in our other terms and policies, including our Terms of
  Service and our Meta Commercial Terms. The term “including” means “including
  without limitation.” 2. Intellectual Property Rights a. Our License to You.
  Subject to your compliance with these Terms and all other applicable terms and
  policies, we grant you a limited, non-exclusive, non-sublicensable (except to
  Service Providers as described below), non-transferable, non-assignable
  license to use, access, and integrate with Platform, but only to the extent
  permitted in these Terms and all other applicable terms and policies. You will
  not sell, transfer, or sublicense Platform to anyone. Except as expressly
  licensed herein, you will not use, access, integrate with, modify, translate,
  create derivative works of, reverse engineer, or otherwise exploit Platform or
  any aspect thereof. The Meta Companies reserve all rights, title, and interest
  (including the right to enforce any such rights) not expressly granted in
  these Terms. b. Your License to Us i. Your Content: 1. You grant us a
  non-exclusive, transferable, sublicensable, royalty-free, worldwide license
  to: host, use, distribute, modify, run, copy, publicly perform or display,
  translate, and create derivative works of any information, data, and other
  content made available by you or on your behalf (including by your Service
  Providers or through your App) in connection with Platform (collectively,
  “Your Content”) for any business purpose in connection with operating,
  providing, or improving Platform or any other Meta Product. This license
  remains in effect even if you stop using Platform. Without limitation, your
  license to us includes: the right to incorporate Your Content into other parts
  of Meta Products, the right to attribute the source of Your Content using your
  name, trademarks, or logos; the right to use Your Content for promotional
  purposes, and the right to analyze Your Content (including to make sure you’re
  complying with these Terms and all other applicable terms and policies). 2. If
  you use the Meta Business Tools to send us Business Tool Data, our use of that
  data is governed by the Business Tools Terms rather than the foregoing license
  for Your Content. 3. If you owned Your Content before providing it to us, you
  will continue owning it after providing it to us, subject to any rights
  granted in these Terms or any other applicable terms or policies and any
  access you provide to others by sharing it via Platform. ii. Your App: 1. You
  grant us a non-exclusive, transferable, sublicensable, royalty-free, worldwide
  license to: host, use, distribute, modify, run, copy, publicly perform or
  display, translate, and create derivative works of your App for any business
  purpose in connection with operating, providing, or improving Platform. This
  license remains in effect even if you stop using Platform. Without limitation,
  the foregoing license includes the right to frame or link to your App, to
  place content (including ads) around your App, and to analyze your App
  (including to assess your compliance with these Terms and all other applicable
  terms and policies). 2. As between the parties, in connection with Platform,
  we won’t be subject to any terms or policies associated with your App or Your
  Content (even if we click or tap agreement). Those terms and policies are
  considered null and void and are rejected and excluded from these Terms. 3.
  Nothing in these Terms will be interpreted as a representation or agreement
  that we will not develop or have not developed apps, products, features, or
  services that are similar to your App or compete with your App. iii. Your
  Name, Trademarks, and Logos: You grant us a non-exclusive, transferable,
  sublicensable, royalty-free, worldwide license to use your name, trademarks,
  and logos for distribution, marketing, and promotional purposes, in connection
  with your use of Meta Products, in all formats and media. This license remains
  in effect for existing materials and instances even if you stop using
  Platform. c. Protecting the Rights of Others i. You will not provide or
  promote content in your App that infringes upon or otherwise violates the
  rights of any person or third party. ii. You will obtain (and represent and
  warrant that you own or have secured) all rights necessary from all applicable
  rights holders to (1) grant the licenses, rights, and permissions in these
  Terms (including those in Section 2.b (“Your License to Us”)); (2) display,
  distribute, and deliver all information, data, and other content in your App;
  and (3) otherwise operate your App. This includes satisfying all licensing,
  reporting, and payout obligations to third parties. iii. If your App contains
  content submitted or provided by your Users or other third parties, you must
  have an appropriate notice and takedown process and otherwise comply with all
  applicable laws and regulations to respond to notices of claimed infringement.
  Without limiting that compliance, in the United States, you must comply with
  all requirements of the Digital Millennium Copyright Act. 3. Data Use a.
  Prohibited Practices. You will not perform, or facilitate or support others in
  performing, any of the following prohibited practices (collectively,
  “Prohibited Practices”): i. Processing Platform Data to discriminate or
  encourage discrimination against people based on personal attributes including
  race, ethnicity, color, national origin, religion, age, sex, sexual
  orientation, gender identity, family status, disability, medical or genetic
  condition, or any other categories prohibited by applicable law, regulation,
  or Meta policy. ii. Processing Platform Data to make eligibility
  determinations about people, including for housing, employment, insurance,
  education opportunities, credit, government benefits, or immigration status.
  By eligibility determinations, we mean determining whether to provide, deny,
  or take away a particular benefit (for example, housing or scholarships) as
  well as determining the terms under which the benefit will be provided,
  denied, or taken away. iii. Processing Platform Data to perform, facilitate,
  or provide tools for surveillance. Surveillance includes the Processing of
  Platform Data about people, groups, or events for law enforcement or national
  security purposes. iv. Selling, licensing, or purchasing Platform Data. v.
  Placing Platform Data on, or otherwise making Platform Data available to, a
  search engine or directory without our prior express written consent. vi.
  Attempting to decode, circumvent, re-identify, de-anonymize, unscramble,
  unencrypt, or reverse hash, or reverse-engineer Platform Data that is provided
  to you. vii. Changing your App’s core functionality or data Processing so that
  Users would view it as an unfamiliar or different App, or materially changing
  the scope of Processing of previously collected Platform Data, unless in each
  case you first re-submit your App and receive our approval through App Review.
  viii. Processing friend lists from Facebook to establish social connections in
  your App unless each person in that connection has granted you access to that
  information for that purpose. b. Additional Terms for Restricted Platform Data
  i. You will not request Restricted Platform Data unless it is necessary to
  meaningfully improve the quality of the applicable User's experience in the
  specific product or service for which the User shared the data. ii. It must be
  clear to the User why you are requesting their Restricted Platform Data in
  order to improve the quality of their experience. iii. For clarity, your
  Processing of Restricted Platform Data must comply with the applicable
  Developer Docs and other provisions of these Terms (including the Prohibited
  Practices). c. Sharing Platform Data. You may only share Platform Data in
  compliance with these Terms (including Sections 3.a (“Prohibited Practices”),
  3.b (“Additional Terms for Restricted Platform Data”), 4 (“Privacy Policy”),
  and 5 (“Service Providers and Tech Providers”)), applicable law and
  regulations, and all other applicable terms and policies, and only in the
  following circumstances: i. With respect to Platform Data collected as a Tech
  Provider, solely as described below in Section 5.b (“Tech Providers”); ii.
  With respect to Platform Data not collected as a Tech Provider, 1. when
  required under applicable law or regulation (you must retain proof of the
  applicable legal or regulatory requirement or request and provide it to us if
  we ask for it); 2. with your Service Provider; 3. when a User expressly
  directs you to share the data with a third party (you must retain proof of the
  User’s express direction and provide it to us if we ask for it); or 4. solely
  with respect to Platform Data that is not Restricted Platform Data, with other
  third parties, so long as: a. you first contractually prohibit them from using
  the Platform Data in a way that would violate these Terms or any other
  applicable terms or policies (you must retain proof of the contractual
  prohibition and provide it to us if we ask for it); and b. you ensure that any
  such third parties comply with these Terms and all other applicable terms and
  policies as if they were in your place, and you are responsible for their acts
  and omissions, including their noncompliance. d. Retention, Deletion, and
  Accessibility of Platform Data i. Unless required to keep Platform Data under
  applicable law or regulation, you must (and must make reasonable efforts to
  ensure your Service Providers) do the following: 1. Make reasonable efforts to
  keep Platform Data up to date, including Platform Data that has been modified
  or deleted. You must update Platform Data promptly after receiving a request
  from us or the User to do so. You must give Users an easily accessible and
  clearly marked way to ask for their Platform Data to be modified or deleted.
  2. Delete all Platform Data as soon as reasonably possible in the following
  cases: a. When retaining the Platform Data is no longer necessary for a
  legitimate business purpose that is consistent with these Terms and all other
  applicable terms and policies; b. When you stop operating the product or
  service through which the Platform Data was acquired; c. When we request you
  delete the Platform Data for the protection of Users (which we will determine
  at our sole discretion); d. When a User requests their Platform Data be
  deleted or no longer has an account with you (unless the Platform Data has
  been aggregated, obscured, or de-identified so that it cannot be associated
  with a particular User, browser, or device), or for Tech Providers, when a
  User or the Client requests their Platform Data be deleted or the Client no
  longer has an account with you; e. When required by applicable law or
  regulations; or f. As required under Section 7 (“Compliance Review Rights and
  Suspension and Termination of these Terms”). ii. If you are required to keep
  Platform Data under applicable law or regulation, you must retain proof of the
  applicable legal or regulatory requirement or request and provide it if we ask
  for it. iii. If you have received Platform Data in error, you must immediately
  report this to us, delete that Platform Data, and provide proof of deletion if
  we ask for it. e. Exceptions to Restrictions. The above provisions of this
  section (Section 3.a-d) do not apply to certain Platform Data as described
  here. 4. Privacy Policy a. If you use Platform to Process Platform Data, you
  will provide and comply with a publicly available and easily accessible
  privacy policy. b. This policy must comply with applicable law and regulations
  and must accurately and clearly explain what data you are Processing, how you
  are Processing it, the purposes for which you are Processing it, and how Users
  may request deletion of that data. c. You may only Process Platform Data as
  clearly described in your privacy policy and in accordance with all applicable
  law and regulations, these Terms, and all other applicable terms and policies.
  d. Your privacy policy will not supersede, modify, or be inconsistent with
  these Terms or any other applicable terms or policies. e. You must retain all
  of your privacy policies in effect while using Platform and provide them to us
  if we ask for them. f. You will maintain publicly available links to your
  privacy policies in the privacy policy field in the settings of your App
  Dashboard, as well as in any App Store that allows you to do so, if
  applicable, and ensure the links remain current and up to date. 5. Service
  Providers and Tech Providers a. Service Providers i. You will not use a
  Service Provider in connection with your use of Platform or Processing of
  Platform Data unless such Service Provider first agrees in writing to do the
  following: 1. Use Platform and Process Platform Data solely for you and at
  your direction in order to provide services you requested in a manner that is
  consistent with these Terms, all other applicable terms and policies, and your
  privacy policy, and for no other individual or entity and for no other
  purpose, including for the Service Provider’s own purposes; and 2. In the
  event the Service Provider engages another Service Provider (“Sub-Service
  Provider”) in order to provide the services requested, ensure the Service
  Provider requires the Sub-Service Provider in writing to comply with the above
  requirements. ii. You must ensure that any Service Provider and Sub-Service
  Provider complies with these Terms and all other applicable terms and policies
  as if they were in your place, and you are responsible for their acts and
  omissions, including their noncompliance. iii. When you cease using a Service
  Provider or Sub-Service Provider, you must ensure they immediately cease using
  Platform and Processing Platform Data and promptly delete all Platform Data in
  their possession or control. iv. Upon our request, you must provide a list of
  your Service Providers and Sub-Service Providers including up-to-date contact
  information for each, the types and volume of Platform Data shared, and proof
  of written agreements with your Service Providers to demonstrate compliance
  with this Section. v. We may prohibit your use of any Service Provider or
  Sub-Service Provider in connection with your use of Platform or Processing of
  Platform Data if we believe that (1) they have violated these Terms or other
  applicable terms or policies or (2) they are negatively impacting Platform,
  other Meta Products, Platform Data, or people who use Meta Products, and will
  provide notice to you if we do. Promptly upon such notice, you must stop using
  that Service Provider or Sub-Service Provider in connection with your use of
  Platform or Processing of Platform Data. vi. We may require that your Service
  Providers or Sub-Service Providers agree to these Terms or other applicable
  terms or policies in order to access Meta Products, Platform, or Platform
  Data. b. Tech Providers i. If you are a Tech Provider, you must comply with
  the other provisions of these Terms, as well as the provisions in this section
  with respect to your use of Platform and Processing of Platform Data as a Tech
  Provider. If the terms conflict, the terms that are more restrictive on you or
  more protective of us apply. ii. You, as a Tech Provider, understand and agree
  to the following: 1. You will only use Platform and Process Platform Data on
  behalf of and at the direction of your Client on whose behalf you access it to
  help such Client to use Platform or Process Platform Data in accordance with
  these Terms and all other applicable terms and policies (“Client’s Purpose”),
  and not for your own purposes or another Client’s or entity’s purposes (for
  example, you will not Process Platform Data to build or augment user profiles
  for your own purposes or another Client’s purposes); 2. You will ensure that
  Platform Data you maintain on behalf of one Client is maintained separately
  from that of other Clients; 3. You will maintain an up-to-date list of your
  Clients and their contact information and provide it to us if we ask for it;
  4. You will only share Platform Data in compliance with these Terms (including
  Sections 3a (“Prohibited Practices”), 3b (“Additional Terms for Restricted
  Platform Data”), and 5a (“Service Providers”)), applicable law and
  regulations, and all other applicable terms and policies, and only in the
  following circumstances: a. with your applicable Client, so long as you first
  contractually prohibit such Client from Processing Platform Data in a way that
  would violate these Terms or any other applicable terms or policies; b. to the
  extent required under applicable law or regulation (you must retain proof of
  the applicable legal or regulatory requirement or request and provide it to us
  if we ask for it); c. with your Service Provider solely to the extent
  necessary for your applicable Client’s Purpose; or d. with your Client’s
  service provider solely to the extent necessary for such Client’s Purpose and
  when such Client expressly directs you to share the data with such service
  provider (you must retain proof of the Client’s express direction and provide
  it to us if we ask for it); 5. We may require that your Clients agree to these
  Terms or other applicable terms or policies in order to access Meta Products,
  Platform, or Platform Data through your App. 6. You will promptly terminate a
  Client’s use of our Meta Products, Platform, or Platform Data through your App
  if we request it because we believe that the Client (a) has violated these
  Terms or other applicable terms or policies or (b) is negatively impacting
  Platform, other Meta Products, Platform Data, or people who use Meta Products.
  6. Data Security a. Data Security Requirements i. You must always have in
  effect and maintain administrative, physical, and technical safeguards that do
  the following: 1. Meet or exceed industry standards given the sensitivity of
  the Platform Data; 2. Comply with applicable law and regulations, including
  data security and privacy laws, rules, and regulations; and 3. Are designed to
  prevent any unauthorized (including in violation of these Terms or any other
  applicable terms or policies) Processing (including, for the avoidance of
  doubt, access, destruction, loss, alteration, disclosure, distribution, or
  compromise) of Platform Data. ii. You must have a publicly available way for
  people to report security vulnerabilities in your App to you, and you must
  promptly address identified deficiencies. iii. You must not solicit, collect,
  store, cache, proxy, or use Facebook or Instagram login credentials of other
  Users. iv. You must not transfer or share user IDs or your access token and
  secret key, except with a Service Provider who helps you build, run, or
  operate your App. b. Incident Reporting i. If any of the following incidents
  happen, you must promptly, and no later than 24 hours after you become aware
  of the incident, notify us and provide us with information we request
  regarding: 1. Any unauthorized (including in violation of these Terms or any
  other applicable terms or policies) Processing (including, for the avoidance
  of doubt, access, destruction, loss, alteration, disclosure, distribution or
  compromise) of Platform Data; or 2. Any incidents that are reasonably likely
  to compromise the security, confidentiality, or integrity of your IT Systems
  or your Service Provider’s or Sub-Service Provider’s IT Systems. ii. You must
  immediately begin remediation of the incident and reasonably cooperate with
  us, including by informing us in reasonable detail of the impact of the
  incident upon Platform Data and corrective actions being taken, and keeping us
  updated about your compliance with any notification or other requirements
  under applicable laws and regulations. 7. Compliance Review Rights and
  Suspension and Termination of these Terms a. App Review. We may require that
  you submit your App for our review or approval (“App Review”). Whether or not
  your App (including its access to any Platform Data) is approved (which will
  be in our sole discretion), you will ensure that your App is compliant with
  these Terms and all other applicable terms and policies, and we may review
  your App for such compliance from time to time, in our sole discretion. You
  will cooperate with our reviews and provide any information we request. We may
  verify information you provide to us during any such reviews or otherwise in
  your App dashboard, which you will update to keep it complete and accurate. b.
  Regular Monitoring. We, or third-party professionals working at our direction
  (including auditors, attorneys, consultants, and/or computer forensics
  analysts) (collectively, “Third-Party Auditors”), may conduct regular
  monitoring of your App and its access to Platform and Processing of Platform
  Data using technical and operational measures. c. Auditing Rights i. We or
  Third-Party Auditors may conduct an Audit, no more than once a calendar year
  unless there is a Necessary Condition, to ensure that your and your App’s
  Processing of Platform Data is and has been in compliance with these Terms and
  all other applicable terms and policies. ii. Audits will be conducted during
  normal business hours after providing you with at least 10 business days’
  written notice (email will suffice), unless we determine in our sole
  discretion a Necessary Condition requires more immediate access. iii. You will
  cooperate with the Audits, including by (1) providing all necessary physical
  and remote access to your IT Systems and Records, and (2) providing
  information and assistance as reasonably requested (including making your
  personnel who are knowledgeable about your or your App’s Processing of
  Platform Data available for our questioning). iv. You will also use
  commercially reasonable efforts to get permission and cooperation from your
  Service Providers for us to conduct such Audits with respect to their IT
  Systems, Records, and applicable personnel. v. You will remedy any
  non-compliance revealed by an Audit as soon as reasonably practicable (as we
  determine based on the facts and circumstances), after which we may conduct
  follow-up Audits to ensure proper remediation of the non-compliance. vi. If an
  Audit reveals any non-compliance by you or your Service Provider(s) then you
  will reimburse us for all of our reasonable costs and expenses associated with
  conducting the Audit and any related follow-up Audits. vii. After these Terms
  have ended, our Audit rights under this Section will survive until 1 year
  after the later of when you affirmatively demonstrate that you have stopped
  Processing all Platform Data and all embodiments thereof that are in your and
  your Service Providers’ possession or control have been deleted. For the
  avoidance of doubt, nothing in this Section limits any other rights or
  remedies we may have by law, in equity, or under these Terms or other
  applicable terms or policies. d. Certifications. From time to time, we may
  request (in writing or through your App dashboard, Platform, or any Meta
  Product) information, certifications, and attestations relating to your use of
  Platform or Processing of Platform Data, which you will provide to us in the
  requested time frame and form. This may include certifying: (i) your
  compliance with these Terms and all other applicable terms and policies, and
  (ii) the purpose or use for the Platform Data you have requested or have
  access to, and that each such purpose or use complies with these Terms and all
  other applicable terms and policies. All such certifications and attestations
  must be provided by an authorized representative of yours. e. Suspension and
  Termination i. We may take enforcement action against you and your App if we
  believe, in our sole discretion, that: 1. You have not timely responded to our
  requests related to monitoring or auditing; 2. You or your App has violated or
  may have violated these Terms or any other applicable terms or policies or is
  negatively impacting Platform, other Meta Products, Platform Data, or people
  who use Meta Products; 3. It is needed to comply with applicable laws or
  regulations or otherwise required or requested by a court order or
  governmental authority; or 4. It is needed to protect the Meta Companies from
  legal or regulatory liability. ii. We may take enforcement action at any time,
  including while we investigate your App, with or without notice to you.
  Enforcement can be both automated and manual. It can include suspending or
  removing your App, removing your access and your App’s access to Platform,
  requiring that you stop Processing and delete Platform Data, terminating our
  agreements with you, or any other action that we consider to be appropriate,
  including terminating other agreements with you or your ability to use Meta
  Products. iii. We may suspend or end your App’s access to any Platform APIs,
  permissions, or features that your App has not used or accessed within a
  90-day period with or without notice to you. 8. Notice After you agree to
  these Terms, any written notice, request, or communications from us to you may
  be provided via email or mail (for example, to the email address or mailing
  address in your App account with us) or via notifications within the Meta
  Products (for example, in your account with us). You will keep your contact
  information current, including name, business name, and email. 9.
  Indemnification In addition to and without limiting the scope of the
  “Indemnification” Section in our Meta Commercial Terms if anyone brings a
  claim, cause of action, or dispute against the Meta Companies related to your
  use of Platform, your Processing of Platform Data, Your Content, or your App,
  name or logo, products or services, or actions in connection with Platform,
  you will indemnify and hold the Meta Companies harmless from and against all
  damages, losses, and expenses of any kind (including reasonable legal fees and
  costs) related to any such claim, cause of action, or dispute. 10.
  International Transfers This section shall apply to the extent that your
  Processing of Platform Data includes personal data controlled by Meta
  Platforms Ireland Limited (“Meta Ireland Data”) and the transfer of such Meta
  Ireland Data to a territory outside of the European Economic Area that, at the
  time of such transfer, does not have a positive adequacy decision from the
  European Commission under Article 45 of Regulation (EU) 2016/679 (each an “EEA
  Data Transfer”). Whenever there is an EEA Data Transfer, your use of Meta
  Ireland Data is subject to your compliance with the Clauses in so far as they
  relate to controller to controller transfers (Module One). In each case, you
  agree that for the purposes of Section IV, Clauses 17 and 18 in the Clauses,
  Option 1 and Option (b) shall apply respectively and the Member State shall be
  Ireland. Nothing in this Section 10 (International transfers) is intended to
  vary or modify the Clauses. For the purposes of the Appendix to the Clauses,
  the following will apply: a. For the purposes of Annex I(A) to the Clauses,
  Meta Platforms Ireland Limited is the "data exporter" and you are the "data
  importer" as defined in the Clauses. b. For the purposes of Annex I(B) to the
  Clauses: i. “Categories of data subjects” are the Users who visit, access,
  use, or otherwise interact with the App and the products and services of Meta
  Platforms Ireland Limited; ii. "Categories of personal data" are Meta Ireland
  Data, which includes profile information, photos and videos, location
  information, communications between Users, information about use of the App
  and other products and services, payment information, device information,
  information about visits to third-party websites or Apps that use a "like" or
  "comment" button or other service integration, information from third-party
  partners or the Meta Companies, or as otherwise set forth in the Data Policy;
  iii. "Sensitive data" is personal data about a person's racial or ethnic
  origin, political opinions, religious or philosophical beliefs, trade-union
  membership, genetic data, biometric data for the purpose of uniquely
  identifying a natural person, data concerning health or data concerning a
  natural person's sex life or sexual orientation, criminal convictions or
  alleged commission of an offence; iv. "Frequency of the transfer" is on a
  continuous basis to the extent required to fulfil the purpose outlined in
  Section 10(b)(v) below; v. Nature and purpose of the data transfer(s)" is the
  provision of the App and other products and services by you to Users pursuant
  to the applicable terms and conditions and Privacy Policy of you and/or your
  Client; and vi. "Period for which personal data will be retained" is as set
  out in Section 3(d)(i)(2) unless applicable laws require the Meta Ireland Data
  be retained for a longer period, in which case you shall only retain such Meta
  Ireland Data for the period required by such applicable laws and subject
  always to Section 10(d). c. For the purposes of Annex I(C) to the Clauses, the
  competent supervisory authority will be the Data Protection Commission in
  Ireland; and d. For the purposes of Annex II to the Clauses, you will
  implement and maintain the technical and organisational security measures set
  out in Section 6 (“Data Security”) and such other measures as we may require
  from time to time. 11. General a. In accordance with our Terms of Service, you
  will not transfer any of your rights or obligations under these Terms to
  anyone else without our prior written consent. Transferring can include
  assignment, acquisition, merger, change of control, or other forms of
  transfer. Any unpermitted transfer will be considered null and void. For any
  permitted transfer, you can continue to Process Platform Data only for your
  App subject to these Terms and only after you re-submit your App and receive
  our approval through our App Review process. b. You also must comply with all
  applicable laws and regulations (including the Children’s Online Privacy
  Protection Act (“COPPA”) and the Video Privacy Protection Act (“VPPA”)). c. If
  there is any conflict between these Terms and any other applicable online
  terms, the terms that are more restrictive on you and your App or more
  protective of us apply. If you have previously agreed to our Supplemental
  Terms for Extended Platform Products and/or our Technology Provider Amendment
  to Supplemental Terms for Extended Platform Products, these Terms hereby
  supersede and replace them. d. We reserve the right to amend these Terms at
  any time. Your continued use of or access to Platform after any such amendment
  will constitute your binding agreement to these Terms as amended. e. We may
  change, suspend, or discontinue the availability of Platform at any time. In
  addition, we may impose limits on certain features and services or restrict
  your access to parts or all of our APIs or websites without notice or
  liability. f. If we elect to provide you with support or modifications for
  Platform, we may discontinue either at any time without notice to you. g. We
  do not guarantee that Platform will always be free. h. We can issue a press
  release or otherwise make public statements or disclosures describing our
  relationship with you or your use of Platform. i. When these Terms have ended,
  all rights granted to you under these Terms will immediately stop and you will
  immediately stop using Platform. The following Sections will remain in effect
  after these Terms have ended: Section 2.b, Section 2.c, Section 3, Section 4,
  Section 5, Section 6, Section 7, Section 9, Section 10, Section 11, and
  Section 12. 12. Glossary a. “App” means any technical integration with
  Platform or to which we have assigned an App identification number. Any code,
  APIs, SDKs, tools, plugins, bots, websites, applications, specifications, and
  other technology made available by you or on your behalf in connection with
  Platform is considered part of your App. b. “Audit” means a review,
  inspection, or audit of your and your Service Providers’ IT Systems or
  Records. c. “Clauses” means the standard contractual clauses annexed to
  European Commission Decision (EU) 2021/914. d. “Client” means the User of a
  Tech Provider’s App. e. “Developer” means the person or entity that creates or
  operates an App. f. “Developer Docs” has the meaning given in Section 1.c
  (“Introduction”). g. “IT Systems” means information technology systems (real
  and virtual), networks, technologies, and facilities (including physical and
  remote access to data centers and cloud facilities) that Process Platform
  Data. h. “Necessary Condition” means any of the following: i. it is required
  by applicable law, rule, or regulation or otherwise required or requested by a
  court order or governmental authority; ii. we suspect that you or your App
  have Processed Platform Data in violation of these Terms or other applicable
  terms or policies; iii. you enter into a change of control transaction or
  transfer (or request to transfer) any of your rights or obligations under
  these Terms or other applicable terms or policies; iv. we determine in our
  sole discretion it is necessary to ensure that you and your App have deleted
  Platform Data in accordance with these Terms and all other applicable terms
  and policies; or v. we determine in our sole discretion it is necessary to
  ensure proper remediation of any non-compliance revealed by an Audit. i.
  “Platform” means the set of APIs, SDKs, tools, plugins, code, technology,
  content, and services that enables others, including app developers and
  website operators, to develop functionality, retrieve data from Meta and any
  other Meta Products, or provide data to us. j. “Platform Data” means any
  information, data, or other content you obtain from us, through Platform or
  through your App, whether directly or indirectly and whether before, on, or
  after the date you agree to these Terms, including data anonymized,
  aggregated, or derived from such data. Platform Data includes app tokens, page
  tokens, access tokens, app secrets, and user tokens. k. “Process” means any
  operation or set of operations performed on data or sets of data, whether or
  not by automated means, including use, collection, storage, sharing, or
  transmission. l. “Prohibited Practices” has the meaning given in Section 3.a
  (“Prohibited Practices”). m. "Records” mean books, agreements, access logs,
  third-party reports, policies, processes, and other records regarding the
  Processing of Platform Data. n. “Restricted Platform Data” means Platform Data
  that (i) reasonably can be used to identify a particular User or device; (ii)
  is accessed using the permissions listed here; or (iii) we otherwise designate
  as Restricted. Notwithstanding the foregoing, Restricted Platform Data does
  not include data that can be accessed using the permissions listed here. o.
  “SDKs” means any object code, source code, or documentation you receive from
  us that helps you create Apps or content for use with the Platform. p.
  “Service Provider” means an entity you use to provide you services in
  connection with Platform or any Platform Data. q. “Tech Provider” means a
  Developer of an App whose primary purpose is to enable Users thereof to access
  and use Platform or Platform Data. r. “Third-Party Auditors” has the meaning
  given in Section 7.b (“Regular Monitoring”). s. “User” means the end user of
  an App (whether a person or an entity). t. “Your Content” has the meaning
  given in Section 2.b (“Your License to Us”).
</div>
