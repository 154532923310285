<div class="holder">
  <h1>Kullanım Koşulları</h1>
  <p>
    <span>
      NOT: BU KULLANIM KOŞULLARI YALNIZCA TÜRKİYE’DE BULUNAN KULLANICILARA
      İLİŞKİNDİR. WEBSİTESİNİ TÜRKİYE DIŞINDA KULLANMANIZ HALİNDE BULUNDUĞUNUZ
      LOKASYONA GÖRE FARKLI KULLANIM KOŞULLARI GEÇERLİ OLACAKTIR. WEBSİTESİ
      BULUNDUĞUNUZ LOKASYONA GÖRE SİZİN İÇİN GEÇERLİ OLACAK KULLANIM KOŞULLARINI
      GÖSTERECEKTİR.
    </span>
  </p>
  <p>
    <strong><span>1. Tanımlar</span></strong
    ><br /><span>&#xa0;</span>
  </p>
  <p>
    <span>Bilgesin: Bilgesin Bilişim Teknolojileri A.Ş.</span>
  </p>
  <p>
    <span
      >Kullanıcı: İnternet sitesi üzerinden anketleri doldurmak ve diğer
      hizmetleri için internet sitesini kullananları.</span
    >
  </p>
  <p>
    <span>Taraf(lar): Kullanıcı ve/veya Bilgesin.</span>
  </p>
  <p>
    <span>Koşullar: Bu kullanım koşullarının tamamı.</span>
  </p>
  <p>
    <span
      >Uygulama: Bilgesin’e ait mobil ve diğer internet bağlantısı olan
      cihazlarda kullanılabilen Bilgesin’in sunduğu ürünlerin/hizmetlerin
      sipariş edilebildiği tüm fikri ve sınai hakları Bilgesin’e ait
      uygulama.</span
    >
  </p>
  <p>
    <span
      >Sözleşme: Taraflar arasında iş bu kullanım koşulları dahilinde,
      kullanıcının internet sitesini kullanımı ile yürürlüğe giren hizmet
      sözleşmesini</span
    >
  </p>
  <p>
    <span
      >Hizmet: Bilgesin’in hizmet verdiği bölgelerde, İnternet Sitesi’nde yer
      alan seçenekler arasından, Kullanıcı tarafından seçilen ve Bilgesin
      tarafından, Kullanıcı’ye sunulan hizmet veya mal.</span
    >
  </p>
  <p>
    <span
      >İnternet Sitesi: Bilgesin’e ait mobil ve diğer internet bağlantısı olan
      cihazlardan erişilebilen Bilgesin’in sunduğu ürünlerin/hizmetlerin sipariş
      edilebildiği tüm fikri ve sınai hakları Bilgesin’e ait www.bilgesiniz.com,
      www.bilgesin.com internet sitesi.</span
    >
  </p>
  <p>
    <strong><span>Kullanım Şartlarının Kabulü</span></strong>
  </p>
  <p>
    <span
      >İşbu Kullanım Şartları, Bilgesin ve sizin aranızda hukuken bağlayıcı bir
      sözleşme ilişkisi kurmaktadır. Bu sebeple, HİZMETİ KULLANMADAN ÖNCE
      KULLANIM ŞARTLARINI DİKKATLİ BİR ŞEKİLDE OKUYUNUZ.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >İşbu Kullanım Şartlarında, herhangi bir sebeple ve herhangi bir zamanda,
      kendi takdirimizde olarak, değişiklik ve düzenlemeler yapma hakkımızı
      saklı tutuyoruz.</span
    >
  </p>
  <p>
    <span
      >İnternet sitesinde yer alan işbu Kullanım Şartlarında değişiklik
      yapabiliriz. Önemli değişikliklerle ilgili olarak, örneğin e-posta
      göndererek ya da Hizmet üzerinden bildirim yaparak, size bildirimde
      bulunabiliriz fakat her durumda bu şekilde bildirimde bulunmak zorunda
      değiliz. Diğer değişiklikler, işbu Kullanım Şartlarının “Son Güncelleme”
      tarihi güncellenerek size bildirilecektir ve her türlü değişiklik ile özel
      bildirim alma hakkından feragat etmektesiniz. Herhangi bir değişikliği
      kabul etmiyorsanız, Hizmet kullanımına son vermeniz gerekmektedir. İşbu
      Kullanım Şartlarında yapılan bir değişikliğinden sonra Hizmetin Kullanımı,
      ilgili değişikliklerin kabul edildiği anlamına gelecektir.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >İŞBU KULLANIM ŞARTLARININ HERHANGİ BİR HÜKMÜNÜ KABUL ETMİYORSANIZ YA DA
      İŞBU KULLANIM ŞARTLARI İLE BAĞLI OLMAYA UYGUN YA DA YETKİLİ DEĞİLSENİZ,
      UYGULAMAYI İNDİRMEYİNİZ, HİZMETİ KULLANMAYINIZ VEYA BAŞKA ŞEKİLDE HİZMETE
      ERİŞMEYİNİZ.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong><span>2. Başvuru ve Üyelik</span></strong
    ><br /><br /><span>&#xa0;</span>
  </p>
  <p>
    <span
      >2.1. Kullanıcılar, İnternet Sitesi’nde kayıt için gerekli olan bölümleri
      doldurup, GSM numarasını veya e-posta adresini onayladıktan sonra,
      şifresini girerek, işbu koşullar dahilinde İnternet Sitesi’ni kullanmaya
      başlayabilir.</span
    >
  </p>
  <p>
    <span
      >2.2. Kullanıcı, başvuru esnasında verdiği tüm bilgilerin daima ve her
      bakımdan eksiksiz, gerçeğe uygun ve güncel olduğunu kabul eder. Kullanıcı,
      bilgilerini her zaman İnternet Sitesi aracılığıyla güncelleyebilir.</span
    >
  </p>
  <p>
    <strong><span>3. İnternet Sitesi’nin Kullanımı</span></strong
    ><br /><span>&#xa0;</span>
  </p>
  <p>
    <span
      >3.1. Kullanıcı, Hizmet’e ilişkin tüm şikayetlerini Bilgesin’in Kullanıcı
      hizmetlerine
      <a href="mailto:info@{{ hostName }}">info&#64;{{ hostName }}</a> e-posta
      adresi üzerinden aktarabilir.</span
    >
  </p>
  <p>
    <span
      >3.2. Bilgesin’in sunduğu hizmetten yararlanan Kullanıcı, İnternet
      Sitesi’ni hukuka ve amacına uygun şekilde kullanmayı ve İnternet Sitesi
      dahilinde yaptığı her işlem ve eylemdeki hukuki sorumluluğunun kendisine
      ait olduğunu kabul eder. Kullanıcı’nin İnternet Sitesi aracılığıyla
      ve/veya dahilinde, Koşullar’a ve hukuka aykırı olarak gerçekleştirdiği
      herhangi bir işlem, eylem ve/veya faaliyetleri nedeniyle Bilgesin doğrudan
      veya dolaylı olarak hiçbir şekilde sorumlu tutulamaz.</span
    >
  </p>
  <p>
    <span
      >3.3. Bilgesin, Kullanıcı’dan kaynaklanan teknik aksaklıklar sebebiyle
      hizmetin geçici ya da kalıcı olarak durdurulması, durumunda
      yükümlülüklerini ifa edememesi nedeniyle sorumlu tutulamayacak ve
      kendisinden herhangi bir nam altında herhangi bir talepte
      bulunulamayacaktır.</span
    >
  </p>
  <p>
    <span
      >3.4 Kullanıcı, İnternet Sitesi üzerinde alınan tüm hizmetlerim, yapılan
      alışverişlerin kişisel kullanım ve tüketim amaçlı olarak son kullanıcıya
      yapıldığını, tekrar satış amaçlı olmadığını kabul eder.</span
    >
  </p>
  <p>
    <span
      >3.5 İnternet Sitesi’nde paylaşılan içeriklere/yorumlara ilişkin olarak;
      içeriklerin yorumların doğruluk, kalite, hukuka uygunluk, yerindelik,
      güvenilirlik, nitelik başta olmak üzere bütün özelliklerinden yalnızca
      Kullanıcı sorumludur.</span
    >
  </p>
  <p>
    <span
      >3.6 Yorumların hukuka uygun olup olmadığını veya bu Kullanım Koşulları’nı
      ihlal edip etmediğini (yasadışı içeriklerin tarafımıza bildirildiği
      durumlar gibi) belirlemek ve yasal yükümlülüklere uyum sağlamak adına,
      Bilgesin yorumları inceleyebilecektir. Ayrıca yasaları ve/veya bu işbu
      Koşulları ihlal ettiği düşünülen içerikler, Bilgesin tarafından
      değiştirilebilir, silinebilir, bu içeriklere yönelik erişim/görüntüleme
      engellenebilir. Bilgesin’in yorumları yayınlamama hakkı saklıdır.</span
    >
  </p>
  <p>
    <span
      >3.28. Kullanıcıler, İnternet Sitesi’nde gerçekleştireceği işlemlerde
      aşağıdaki kapsamda yorum, paylaşım ve diğer içerikler paylaşamazlar:</span
    >
  </p>
  <ul style="margin: 0pt; padding-left: 0pt">
    <li>
      <span>Sakıncalı veya yasalara aykırı her türlü içerik.</span>
    </li>
    <li>
      <span
        >Diğer kullanıcılar ve/veya üçüncü kişiler için zarar verici, hakaret
        içerikli, kişilik haklarını zedeleyen ve/veya kırıcı içerikler.</span
      >
    </li>
    <li>
      <span>Herhangi bir kişi veya kurumu tehdit eden içerikler.</span>
    </li>
    <li>
      <span
        >Herhangi bir kişiye yönelik saldırgan veya zarar verici içerikler</span
      >
    </li>
    <li>
      <span
        >Kullanıcıların genelini rahatsız eden/edebilecek olan içerikler.</span
      >
    </li>
    <li>
      <span
        >Tek bir kullanıcı tarafından sürekli olarak paylaşılan, konu ve sayfa
        içeriği ile alakasız içerikler.</span
      >
    </li>
    <li>
      <span
        >Bir veya birden çok kullanıcı tarafından paylaşılan birbirinin aynısı
        içerikler (spam).</span
      >
    </li>
    <li>
      <span>Bir başka marka veya kurum adına reklam içerikleri.</span>
    </li>
    <li>
      <span
        >Yasa dışı girişimleri destekleyen ve teşvik eden her türlü yorum,
        bağlantı ve içerikler.</span
      >
    </li>
    <li>
      <span
        >Herhangi bir üçüncü şahıs veya kurumun haklarını ihlal eden her türlü
        içerik.</span
      >
    </li>
  </ul>
  <p>
    <br /><span
      >Kullanıcı, İnternet Sitesi’ni kullanım sırasında üçüncü kişilere ilişkin
      kişisel bilgileri izinsiz şekilde paylaşmayacağını, üçüncü kişilere ait
      kişisel bilgi ve verilerin izinsiz şekilde paylaşılmasının Kişisel
      Verilerin Korunmasına ilişkin mevzuata aykırılık teşkil ettiğini ve Türk
      Ceza Kanunu kapsamında suç teşkil edebileceğini, bu sebeple meydana
      gelebilecek olası bir veri ihlalinden kaynaklı sorumluluğun kendisine ait
      olacağını ve ilgili kullanıcıya rücu edileceğini kabul ve beyan eder.
    </span>
  </p>
  <p>
    <span>4. Sona Erme</span>
  </p>
  <p>
    <span
      >4.1. Kullanıcı, İnternet Sitesi kullanımına istediği zaman son
      verebilir.</span
    >
  </p>
  <p>
    <span
      >4.2. Kullanıcı’nin işbu belirtilen Koşullar’a, ilgili mevzuata veya iyi
      niyet/ahlak kurallarına aykırı davrandığının tespiti halinde, Bilgesin
      Kullanıcı’nin üyeliğini askıya alma veya İnternet Sitesi’nden/Uygulama’dan
      tamamen çıkarma hakkını saklı tutar.</span
    >
  </p>
  <p>
    <span>5. Kişisel Verilerin Korunması</span>
  </p>
  <p>
    <span>5.1. Kullanıcı Kişisel Verilerinin Korunması Politikası</span>
  </p>
  <p>
    <span
      >Bilgesin, özel hayatın gizliliği ve kişisel verilerinizin korunması
      herşeyden önce gelir. Sağlanan hizmet, kişisel veri niteliğinde minimum
      bilginin kullanılmasını gerektirecek şekilde tasarlanmıştır. İnternet
      sitesi (www.bilgesin.com) kullanıcılara ait kişisel veri niteliğinde
      telefon numarası ve e-posta adresi haricinde başka hiçbir veriyi,
      toplamaz, saklamaz ve üçüncü şahıslarla paylaşmaz. Telefon numarası
      bilgisi hizmetin kullanımından sonra şifrelenerek saklanır, Bilgesin
      çalışanları dahil herkesin erişimine bu veri kapatılır. Üçüncü şahıslarla
      paylaşılmaz.</span
    >
  </p>
  <p>
    <span>5.2. Kişisel Verilerinizin Korunmasına İlişkin Aydınlatma Metni</span>
  </p>
  <p>
    <span
      >İşbu Aydınlatma Metni, yerel mevzuatımızda yer alan 6698 sayılı Kişisel
      Verilerin Korunması Kanunu (</span
    ><strong><span>“KVKK”</span></strong
    ><span>) ile Avrupa Birliği Genel Veri Koruma Tüzüğü (</span
    ><strong><span>“GDPR”</span></strong
    ><span
      >) uyarınca, Bilgesin’e ait internet sitesini kullananlara (Kullanıcılara)
      ait kişisel verilerin işlenmesi, saklanması, aktarılması ve imhasına
      ilişkin usul ve esaslar hakkında veri sahiplerinin aydınlatılması amacıyla
      hazırlanmıştır.</span
    >
  </p>
  <p>
    <span>5.2.1-Hangi Kişisel Verileri İşliyoruz?</span>
  </p>
  <p>
    <span
      >Kullanıcıların kişisel verileri ile özel nitelikli kişisel verileri, KVKK
      m.4 ve GDPR m.5 çerçevesinde, KVKK 5. ve 6. maddeleri ile GDPR 6. ve 9.
      maddelerinde belirtilen Kişisel Veri İşleme Şartları ve Amaçları
      doğrultusunda ve işbu Aydınlatma Metninde yer alan amaçlar kapsamında
      kalmak üzere; hukuka uygun şekilde, işleme amacıyla bağlantılı, sınırlı ve
      ölçülü olarak ve veri güvenliğinin sağlanması amacıyla mevzuatta öngörülen
      her türlü teknik ve idari tedbirler alınarak işlenecektir.</span
    >
  </p>
  <p>
    <span>a-İletişim Bilgileriniz:</span><span>&#xa0;</span
    ><span>
      telefon numaranız (bildirdiğiniz ev/iş yeri sabit ve/veya mobil telefon
      numaralarınız), e-posta adresiniz</span
    >
  </p>
  <p>
    <span
      >5.2.2. Kişisel Verilerinizi Hangi Amaçlarla İşliyor ve Aktarıyoruz?</span
    >
  </p>
  <p>
    <span
      >Yukarıda yer alan Kişisel Verileriniz; Bilgesin olarak www.bilgesin.com,
      www.bilgesiniz.com (İnternet Sitesi) ile ilgili sair hukuki
      yükümlülüklerimizi yerine getirmek, kullanıcılara sunduğumuz hizmetin
      planlanması ve yönetimi, hizmetlerimizi geliştirmek amacıyla analiz yapma,
      sizlere hizmetlerimize ilişkin faturalandırma yapılması, kimliğinizin
      doğrulanması, suistimal ve yetkisiz işlemlerin izlenmesi ve engellenmesi,
      tarafımızca sunulan hizmetlere ilişkin olarak her türlü soru ve
      şikâyetinize cevap verebilme; anlaşmalı kurumlarla size sunulan indirim ve
      sair avantajlara ilişkin sağlayıcılarla finansal mutabakat sağlanması;
      doğabilecek hukuki uyuşmazlıklarda, talep halinde ve mevzuat gereği ilgili
      yargı mercilerine ve ilgili kamu görevlilerine bilgi verebilmek,
      gerektiğinde yukarıda belirttiğimiz amaçlar dahilinde yetkili kamu kurum
      ve kuruluşları ile yürütmekte olduğumuz bilimsel ve sektörel
      çalışmalarımız esnasında, anonimleştirmenin gerekli olduğu aşamalarda
      anonimleştirme sağlanarak aktarılabilecektir.</span
    >
  </p>
  <p>
    <strong
      ><span
        >5.2.3. Kişisel Verilerinizin Toplanma Yöntemleri Ve Bunlara İlişkin
        Hukuki Sebepler Nelerdir?</span
      ></strong
    >
  </p>
  <p>
    <span
      >Yukarıda belirtilen kişisel verileriniz 6698 sayılı Kanun’un 5. ve 6.
      maddelerinde belirtilen nedenler ve ayrıca;</span
    >
  </p>
  <p>
    <strong><span>a)</span></strong
    ><span>&#xa0;</span
    ><span>Hukuka ve dürüstlük kurallarına uygun olmak,</span>
  </p>
  <p>
    <strong><span>b)</span></strong
    ><span>&#xa0;</span><span>Doğru ve gerektiğinde güncel olmak,</span>
  </p>
  <p>
    <strong><span>c)</span></strong
    ><span>&#xa0;</span
    ><span>Belirli, açık ve meşru amaçlar ile uyumlu olmak,</span>
  </p>
  <p>
    <strong><span>d)</span></strong
    ><span>&#xa0;</span
    ><span>İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olmak,</span>
  </p>
  <p>
    <strong><span>e)</span></strong
    ><span>&#xa0;</span
    ><span
      >İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli olan süre
      kadar muhafaza edilmek üzere otomatik olan (ör., kamera kayıtları) ya da
      herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan
      yollarla (ör., tarafınızdan doldurulan formlar) işlenecek, kaydedilecek,
      depolanacak, muhafaza edilecek, sınıflandırılacak, aktarılabilecek ve
      saklama süresi sonunda imha edilecektir.</span
    >
  </p>
  <p>
    <strong
      ><span
        >5.2.4. Kişisel Verilerin İşlenmesine Dair Haklarınız Nelerdir ve Bu
        Haklara Nasıl Erişebilirsiniz?</span
      ></strong
    >
  </p>
  <p>
    <span
      >Anayasa’nın 20. maddesinde herkesin, kendisiyle ilgili kişisel veriler
      hakkında bilgilendirilme hakkına sahip olduğu ortaya konulmuş olmakla,
      kişisel veri sahibinin 6698 Sayılı Kişisel Verilerin Korunması Kanunu
      kapsamındaki hakları, KVKK’nın 11. maddesinde sayılmıştır. KVKK
      kapsamındaki haklarınıza ilişkin taleplerinizi “Veri Sorumlusuna Başvuru
      Usul ve Esasları Hakkında Tebliğ” Madde 5’e göre Bilgesin’e, (<a
        href="mailto:info@{{ hostName }}"
        >info&#64;{{ hostName }}</a
      >) mail adresine yazılı olarak iletebilirsiniz.</span
    >
  </p>
  <p>
    <span
      >5.3. Kullanıcı, İnternet Sitesi’nde oluşturulmuş her bir kullanıcı için
      yalnızca bir adet GSM numarası ve e-posta adresi tanımlanabileceğini,
      tanımlanan GSM numarasının ve e-posta adresinin değiştirilemeyeceğini,
      yeni bir GSM numarasının ve e-posta adresinin ancak İnternet Sitesi
      üzerinde yeni bir kullanıcı oluşturulması halinde tanımlatılabileceğini,
      kayıtlı GSM numarası ve e-posta adresi ile yapacağı her türlü işlemden
      bizzat kendisinin sorumlu olduğunu; kabul, beyan ve taahhüt eder.</span
    >
  </p>
  <p>
    <span>6. Fikri Mülkiyet Hakları</span>
  </p>
  <p>
    <span
      >6.1. Bilgesin'in; Bilgesin hizmetleri, fikri mülkiyet hakları, Bilgesin
      ticari markaları, Bilgesin ticari görünümü veya İnternet Sitesi’nin
      tasarım, metin, imge, html kodu ve diğer kodlar da dahil fakat bunlarla
      sınırlı olmamak kaydıyla, tüm unsurları, her tür ayni ve şahsi hakları,
      ticari bilgi ve birikim’e yönelik tüm hakları münhasıran Bilgesin’e
      aittir.</span
    >
  </p>
  <p>
    <span
      >6.2. Kullanıcı, Bilgesin hizmetlerini ve Bilgesin'in yukarıda sayılan
      çalışmalarını ticari amaçla kullanamaz, çoğaltamaz, dağıtamaz veya
      bunlardan türetilmiş çalışmalar yapamaz veya hazırlayamaz.</span
    >
  </p>
  <p>
    <span
      >6.3. Kullanıcı, İnternet Sitesi dahilinde bulunan, Bilgesin ve/veya başka
      bir üçüncü kişilere ait resimleri, metinleri, görsel ve işitsel imgeleri,
      video kliplerini, dosyaları, veri tabanlarını, katalogları ve listeleri
      çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini kabul
      eder.</span
    >
  </p>
  <p>
    <span>7. Değişiklikler</span>
  </p>
  <p>
    <span
      >Bilgesin, tamamen kendi takdirine bağlı ve tek taraflı olarak, işbu
      Koşullar’ı, uygun göreceği herhangi bir zamanda, İnternet Sitesi üzerinden
      ilan ederek değiştirebilir.</span
    >
  </p>
  <p>
    <span>8. Mücbir Sebepler</span>
  </p>
  <p>
    <span
      >Hava muhalefetleri, ulaşım kesintisi, altyapı ve internet arızaları,
      yangın, deprem, sel baskını, diğer doğal afetler ile salgın hastalık gibi
      olağanüstü olayları, kargaşa, yaygın şiddet hareketleri, grev veya resmi
      makamların düzenlemeleri dahil ancak bunlarla sınırlı olmaksızın
      yükümlülüklerini yerine getirmesini tarafsız olarak engelleyebilecek veya
      geciktirebilecek nitelikteki durumlarda Bilgesin, işbu koşullar ile
      belirlenen edimlerinden herhangi birini geç veya eksik ifa etme veya ifa
      etmeme nedeniyle sorumlu tutulamaz.</span
    >
  </p>
  <p>
    <span>9. Uygulanacak Hukuk Ve Yetki</span>
  </p>
  <p>
    <span
      >9.1. İşbu Koşullar Türkiye Cumhuriyeti yasalarına tabi olacak ve onlara
      göre yorumlanacaktır.</span
    >
  </p>
  <p>
    <span
      >9.2. Taraflar, Koşullar’dan doğan ihtilaflarda mevzuat çerçevesinde
      belirlenen parasal sınırlar dahilinde Kullanıcı ve Bilgesin’in kayıtlı
      adresinin bulunduğu yerdeki tüketici hakem heyetlerinin, parasal sınırları
      aşan uyuşmazlıklarda Kullanıcı’nin ve Bilgesin’in bulunduğu yerdeki
      tüketici mahkemelerinin yetkili olacağını kabul etmiştir.</span
    >
  </p>
  <p>
    <span
      >9.3. Uyuşmazlığın tüketici uyuşmazlığı olmaması halinde, İstanbul Merkez
      (Çağlayan) Mahkemeleri ve İcra Daireleri yetkili olacaktır.</span
    >
  </p>
  <p>
    <span>10. Delil Sözleşmesi</span>
  </p>
  <p>
    <span
      >Taraflar, Koşullar’dan doğabilecek her türlü ihtilafta Bilgesin’in resmi
      defter ve ticari kayıtlarıyla, veritabanında ve sunucularında tuttuğu
      elektronik bilgilerin, bilgisayar ve ses kayıtlarının, delil
      teşkiledeceğini, bu maddenin Hukuk Muhakemeleri Kanunu’nun 193. maddesi
      anlamında delil sözleşmesi niteliğinde olduğunu kabul eder.</span
    >
  </p>
  <p>
    <span>11. Yürürlük</span>
  </p>
  <p>
    <span
      >Kullanıcı İnternet Sitesi üzerinden üyelik oluşturması ve/veya İnternet
      Sitesi’nin kullanması esnasında işbu Koşullar’a tabi olacağını kabul
      eder</span
    ><u><span>HakkımızdaKoşullarıGizlilik Politikası</span></u>
  </p>
</div>
