import { HttpClient } from "@angular/common/http";
import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { verifyPhoneNumber } from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  state = "login";
  loginState = "email";
  registerState = "email";
  registerAuthState = false;
  openConsentAgreement = false;
  ticariIletiAgreement = false;
  email = "";
  phone = "";
  password = "";

  showForgotPassword = false;

  constructor(
    private router: Router,
    private user: UserService,
    private http: HttpClient,
    private metaService: MetaService
  ) {}

  ngOnInit(): void {
    this.metaService.set({
      title:
        "Giriş Yap - Anket çözüp para kazanmak için giriş yapın - Bilgesin",
      description:
        "Para kazandıran siteler içerisinde en güvenilir olanına kayıt olun. Formu doldurarak hediye kazanma fırsatını kaçırmayın.",
    });
  }

  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Enter") {
      if (this.state === "login") {
        this.login();
      } else if (this.state === "register") {
        this.register();
      }
    }
  }

  changeState(value: string) {
    this.state = value;
  }

  async login() {
    if (!this.password) {
      return alert("Lütfen tüm alanları doldurunuz");
    }
    if (this.loginState === "email" && !this.email) {
      return alert("Lütfen tüm alanları doldurunuz");
    }
    if (
      this.loginState === "phone" &&
      !verifyPhoneNumber(this.phone.toString())
    ) {
      return;
    }

    const resultCode = await this.user.login({
      email: this.email,
      password: this.password,
      phone: this.phone,
      isLoginPage: true,
      loginState: this.loginState,
    });
    if (resultCode === "NO_USER") {
      this.state = "register";
    }
  }

  register() {
    if (this.registerState != "phone") {
      if (!this.email) {
        alert("Lutfen 'e-posta' giriniz");
        return false;
      }
      if (this.email.indexOf("@") < -1) {
        alert("Lutfen gecerli bir 'e-posta' giriniz");
        return false;
      }
    } else {
      if (!verifyPhoneNumber(this.phone.toString())) {
        return false;
      }
    }

    this.user
      .register({
        email: this.email,
        phone: this.phone,
        registerState: this.registerState,
        openConsentAgreement: this.openConsentAgreement,
        ticariIletiAgreement: this.ticariIletiAgreement,
      })
      .then((res: any) => {
        if (res === "login") {
          this.state = "login";
          this.loginState = this.registerState.toString();
        }
      });
  }

  hideResetPassword(result) {
    this.showForgotPassword = false;

    if (!result || !result.resetStatus) {
      return;
    }

    this.password = result.password;
    this.phone = result.phone;
    this.login();
  }
}
