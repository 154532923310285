<div class="holder">
  <h1>Bilgesin KVKK Gizlilik ve Çerez Politikası</h1>
  <p>
    <span>Bilgesin Bilişim Teknolojileri Anonim Şirketi</span
    ><strong><span> (“Şirket” </span></strong><span>veya</span
    ><strong><span> “Bilgesin”)</span></strong
    ><span>
      olarak, siz değerli müşterilerimizin ve ziyaretçilerimizin kişisel
      verilerinin korunmasına önem veriyoruz. Bu sebeple, Şirket</span
    ><strong><span> </span></strong
    ><span>tarafından kurulan, tasarlanan ve işletilen mobil uygulamayı </span
    ><strong><span>(“Uygulama”)</span></strong
    ><span>,</span><strong><span> </span></strong
    ><span
      >ve Bilgesin’e ait sair dijital mecrayı ziyaretiniz vasıtasıyla elde
      edilebilecek ve/veya elde edilmesi gereken ve 6698 sayılı Kişisel
      Verilerin Korunması Kanunu’na </span
    ><strong><span>(“KVKK”)</span></strong
    ><span>
      uygun olarak işlediğimiz kişisel verileriniz konusunda sizi aydınlatmak ve
      bilgilendirmek istiyoruz.</span
    >
  </p>
  <p>
    <span>Mobil uygulama/sair dijital mecra, bundan sonra </span
    ><strong><span>Uygulama</span></strong
    ><span> veya </span><strong><span>Platformlar</span></strong
    ><span> olarak anılacaktır.</span><span>&#xa0;</span>
  </p>
  <p>
    <span
      >Platformlar aracılığıyla, ürün ve hizmetlerimizden faydalanmak ve
      alışverişlerinizi yönetmek amacıyla, dönemsel olarak veya kendi
      takdirimizde belirleyeceğimiz etkinlik, yenilik ve kampanyalardan haberdar
      olmak için anketlere katılım göstermeniz veya yalnızca ziyaretçi olarak
      Platformlar’ı ziyaret etmeniz durumunda bazı bilgiler Şirket tarafından
      toplanmakta, depolanmakta, korunmakta ve/veya saklanmaktadır. Bu veriler
      kişisel bilgi içerebilir veya içermeyebilir.</span
    >
  </p>
  <p>
    <span
      >Aşağıda, işbu metin kapsamında yukarıda anlatılan işlemlerden
      kaynaklanacak şekilde Şirket tarafından elde edilen/edilecek
      bilgilerinizin nasıl ve ne şekilde toplanacağı, depolanacağı, korunacağı
      ve/veya saklanacağı ve bu bilgiler üzerindeki haklarınız düzenlenmiştir.
      Unutulmamalıdır ki, Şirket bilgilerinizi bu metin ve KVKK kapsamında
      korumaktadır.</span
    ><span>&#xa0;</span>
  </p>
  <p>
    <span
      >Tarafımızla paylaştığınız veya Platformlar’ın kullanılması sebebiyle elde
      ettiğimiz kişisel verileriniz, aşağıdaki sınırlar dâhilinde kalmak
      kaydıyla,</span
    ><span>&#xa0;</span><span>üyelik süresince veya</span><span>&#xa0;</span
    ><span>herhangi</span><span>&#xa0;</span
    ><span>bir yasal süre öngörülmüşse bu süre veya</span><span>&#xa0;</span
    ><span>Şirket’in</span><span>&#xa0;</span
    ><span>ilan ettiği/edeceği süre boyunca işleme tabi tutulacak ve</span
    ><span>&#xa0;</span><span>Platformlar’daki</span><span>&#xa0;</span
    ><span>hizmetlerinin sunulabilmesi amacıyla,</span><span>&#xa0;</span
    ><span>yurtiçi</span><span>&#xa0;</span><span>ya da yurtdışında mukim</span
    ><span>&#xa0;</span
    ><span
      >üçüncü kişi iş ortakları ve/veya tedarikçilere aktarılabilecektir.</span
    ><span>&#xa0;</span>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong
      ><span
        >Kişisel Verilerin Toplanması, İşlenme Amaçları ve Yasal
        Dayanaklar</span
      ></strong
    ><strong><span>&#xa0;</span></strong>
  </p>
  <p>
    <span
      >Kişisel veri, sizi tanımlayan ve sizi tanımlamak için kullanılabilecek
      bilgilerdir. Şirket ile Platformlar üzerinden paylaştığınız bilgiler
      kişisel veri kapsamına girebilir. Kişisel verilere örnek olarak ad-soyad
      gibi kimlik bilgileriniz, e-posta ve telefon numarası gibi iletişim
      bilgileriniz, cinsiyet, yaş aralığınız, çalışma durumunuz, alışveriş
      tercihleriniz ve Şirket’e iletmek istediğiniz mesajınız sayılabilir.
      Şirket’e verdiğiniz bilgilerin bazıları zorunlu olarak verilen (IP
      bilgileri gibi) bilgiler, bazıları da tercihinize bağlı olarak bizimle
      paylaştığınız veya erişmemize onay verdiğiniz bilgilerdir.</span
    ><span>&#xa0;</span>
  </p>
  <p>
    <span
      >Platformlar’ı kullandığınızda sizden aşağıdaki bilgileri sizin bize
      sağlamanız durumunda aşağıdaki yöntemlerle elde edebiliriz:</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong><span>İletişim Hattı</span></strong
    ><strong><span>&#xa0;</span></strong
    ><strong><span> Aracılığıyla Paylaşacağınız Bilgiler:</span></strong>
  </p>
  <p>
    <span
      >Şirket, Platformlar üzerindeki iletişim sekmesi aracılığıyla müşterilere,
      potansiyel müşterilere ve ziyaretçilere bilgi vermek için sunduğu hizmet
      sırasında ad- kişisel bilgilerinizi elde etmektedir. Destek süreçlerinde
      paylaşacağınız kişisel bilgileriniz</span
    ><span>&#xa0;</span
    ><span>
      talep ve şikayetlerinize karşılık verilebilmesi, sizlerle daha etkili bir
      iletişim kurma vasıtasıyla daha iyi bir hizmet sağlanabilmesi ve ürün ve
      hizmetlerimize yönelik memnuniyetinizi artırmak</span
    ><span>&#xa0;</span
    ><span>
      amacıyla Şirket’in meşru menfaatleri kapsamında toplanmakta ve
      işlenmektedir.</span
    ><span>&#xa0;</span>
  </p>
  <br />
  <p>
    <strong><span>Anket Bilgileri:</span></strong>
  </p>
  <p>
    <span
      >Bilgesin hizmetlerini kullanarak anlaşmalı markalarımızdan hediye,
      promosyon ve indirim kodu gibi avantajlardan faydalanabilmeniz için
      tarafınıza sağladığımız anketleri doldurabilirsiniz. Bu anketler ile
      sizden yalnızca e-posta veya telefon numaranızı, cinsiyet, yaş aralığınız,
      çalışma durumunuz, alışveriş tercihleriniz gibi</span
    ><span>&#xa0; </span
    ><span>kişisel bilgilerinizi elde edebiliriz. Şirket söz konusu anket</span
    ><span>&#xa0; </span
    ><span
      >bilgilerinizi açıkça rıza göstermeniz durumunda ayrıca pazarlama
      faaliyetleri aracılığıyla işleme tutup anlaşmalı olduğu markalara da
      aktarabilecektir.</span
    ><span>&#xa0;</span><span> Anket bilgilerinizi: </span>
  </p>
  <ul>
    <li>
      <span
        >Tarafınızla üyelik ilişkisi kurabilmemiz ve üyelik ilişkisinden
        kaynaklı hak ve yükümlülüklerimizi yerine getirebilmemiz (kullanıcı
        kaydı oluşturulması için doldurulması zorunlu olan e-posta ve telefon
        numarası bilgileriniz; faydalanmak istediğiniz indirim, hediye kodu ve
        benzeri promosyonlara uygunluğunuzu değerlendirebilmemiz için cinsiyet,
        yaş aralığınız, çalışma durumunuz, alışveriş tercihleriniz);</span
      >
    </li>
    <li>
      <span
        >Sizlerin temel hak ve özgürlüklerinize zarar vermemek kaydıyla,
        sizlerin verilerini işlememizin meşru menfaatlerimiz kapsamında zorunlu
        oluşu (Uygulama yönetiminizi kolaylaştırmak için katıldığınız anketleri
        ve Uygulama hareketlerinizi saklamamız);</span
      >
    </li>
    <li>
      <span
        >Açık Rızanız (cinsiyet, yaş aralığınız, çalışma durumunuz, alışveriş
        tercihlerinizin size özel pazarlama ve tanıtım faaliyetlerinin organize
        edilmesi amacıyla işlenmesi ve anlaşmalı olduğumuz markalarla
        paylaşılması) yasal dayanakları kapsamında işleme tabi tutabilir.</span
      >
    </li>
  </ul>
  <p>
    <span
      >Ayrıca, tarafınıza Bilgesin veya anlaşmalı olduğu markalar tarafından
      gönderilecek pazarlama,</span
    ><span>&#xa0; </span
    ><span
      >tanıtım ve reklam amaçlı iletişimlere ilişkin detaylı bilgiyi
      Platformlarda yer alan “Elektronik Ticari İleti Bilgilendirme Metni”
      sekmesinde bulabilirsiniz.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong><span>Kayıt Bilgileri:</span></strong>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Bilgesin, ziyaretçileri veya potansiyel müşterilerinin devamlı olarak
      Paltform hizmetlerinden faydalanması için Platformlar’a kayıt kapsamında
      telefon numarası veya e-posta adresi</span
    ><span>&#xa0; </span
    ><span>kişisel verilerinizi elde edebilir. Kayıt bilgilerinizi:</span>
  </p>
  <ul>
    <li>
      <span
        >Tarafınızla üyelik ilişkisi kurabilmemiz ve üyelik ilişkisinden
        kaynaklı hak ve yükümlülüklerimizi yerine getirebilmemiz (üyelik hesabı
        oluşturulması için doldurulması zorunlu olan telefon numarası ve e-posta
        bilgileriniz);</span
      >
    </li>
    <li>
      <span
        >Hukuki yükümlülüğümüzü yerine getirebilmek için zorunlu olan durumlar
        (6563 sayılı Kanun’dan doğan elektronik kayıtları saklama yükümlülüğümüz
        kapsamında doldurulması verdiğiniz onay ve red kayıtlarının saklanma
        zorunluluğu bulunması );</span
      >
    </li>
    <li>
      <span
        >Sizlerin temel hak ve özgürlüklerinize zarar vermemek kaydıyla,
        sizlerin verilerini işlememizin meşru menfaatlerimiz kapsamında zorunlu
        oluşu (Uygulama yönetiminizi kolaylaştırmak için üyeliğe bağlı aldığınız
        programları ve Uygulama hareketlerinizi saklamamız); ve</span
      >
    </li>
  </ul>
  <p>
    <span
      >Platformlar’ı kullandığınızda sizden aşağıdaki bilgileri tamamen </span
    ><strong><span>otomatik yollarla</span></strong
    ><span> aşağıdaki yöntemlerle elde edebiliriz:</span>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong
      ><span
        >Değerlendirilmesini veya incelenmesini istediğiniz hizmet
        verileri:</span
      ></strong
    ><span>
      Platformlar’daki genel hizmet yöneliminizi gösterir bilgiler ile isteyerek
      paylaştığınız diğer veriler, değerlendirilmek üzere altyapımıza kaydedilir
      ve daha sonraki aşamalarda sizlere daha iyi hizmet sunmak üzere
      kullanılabilir.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong><span>Kullanım Verileri:</span></strong
    ><span>
      Platformlar’a ziyaretçi olarak girdikten sonra hakkınızda kullanım ve
      trafik verileri toplanır. Platformlar’da hangi bölümleri ziyaret
      ettiğiniz, neleri tıkladığınız, bu eylemleri ne zaman gerçekleştirdiğiniz,
      hangi site aracılığıyla yönlendirildiğiniz, Platformlar’a erişmek için
      kullandığınız arama sözcükleri gibi veriler buna dâhil olabilir. Ayrıca,
      ağ sunucularımız da günlük dosyaları tutar; bu dosyalar bir aygıtın onlara
      her erişiminde veri kaydeder. Bu işimizi daha iyi anlamamıza,
      operasyonlarımızı analiz etmemize, Uygulamayı sürdürmemize,
      iyileştirmemize, yenilememize, planlamamıza, tasarlamamıza ve
      geliştirmemize yardımcı olmaktadır. Ayrıca verileri istatistiksel analiz
      etmek, tekliflerimizi test etmek ve iyileştirmek amacıyla kullanmaktayız.
      Bu, kullanıcılarımızın Uygulamanın hangi özelliklerini ve eğitim
      planlarını daha çok beğendiğini, Uygulamamızı hangi kullanıcı
      kategorilerinin kullandığını daha iyi anlamamızı sağlamaktadır. Sonuç
      olarak, genellikle bu veri işlemeden elde edilen sonuçlara dayanarak
      Hizmeti nasıl iyileştireceğimize karar vermekteyiz</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong><span>Aygıt/cihaz Verileri:</span></strong
    ><span>
      Hizmetlerimize erişmek için kullandığınız tablet veya telefon gibi bir
      mobil cihaz kullandığınızda, cihazındaki IP adresiniz, işletim sistemi
      sürümünüz, cihaz türünüz, sistem ve performans bilgileriniz ve tarayıcı
      türünüz gibi veriler toplanır. Şirket ayrıca cihazınızdaki "cihaz
      tanımlayıcılara" erişebilir, bunları toplayabilir, izleyebilir,
      cihazınızda depolayabilir ve/veya uzaktan bir veya daha fazla "cihaz
      tanımlayıcı" depolayabilir. Cihaz tanımlayıcılar, mobil cihazınızda
      depolanan veya mobil cihazınızla ilişkilendirilen ve mobil cihazınızı
      benzersiz bir şekilde tanımlayan küçük veri dosyaları veya benzer veri
      yapılarıdır. Cihaz tanımlayıcı; cihazın donanımına, işletim sistemine veya
      diğer yazılımlara bağlı olarak depolanan veriler veya Şirket’in cihaza
      gönderdiği veriler olabilir. Bir cihaz tanımlayıcı, bize veya üçüncü
      kişilere Şirket’in hizmetini nasıl kullandığınız hakkında bilgiler
      verebilir ve bizim veya başkalarının raporlar, kişiselleştirilmiş
      içerikler ve reklamlar sunmasına yardımcı olabilir. Cihaz tanımlayıcıların
      kullanılabilirliğinin bozulması veya engellenmesi halinde, sunduğumuz
      hizmetlerdeki bazı özellikler düzgün çalışmayabilir.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Şirket, otomatik yöntemlerle toplanan yukarıda belirtilen verileri, Site
      hizmetlerinin ziyaretçi ve üyelerin kullanımına temin etmek, kolay
      erişilebilirliğini sağlamak, analiz etmek, geliştirmek, Site performansını
      artırarak optimizasyonu sağlamak ve ziyaretçi trafik ve eylemlerini takip
      ederek dolandırıcılığı engellemek ve Site güvenliğini sağlanmak
      amaçlarıyla meşru menfaatleri kapsamında işleme tabi tutabilecektir.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong><span>Kişisel Verilerin Gizliliği ve Güvenlik</span></strong>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Şirket’in topladığı bilgiler, Şirket’in hizmetlerini optimize etmek,
      geliştirmek, mevcut müşterilerle ve potansiyel müşterilerle iletişime
      geçmek için çeşitli şekillerde toplu halde kullanılabilir. Toplanan hiçbir
      bilgi kamuya açıklanmayacaktır.</span
    ><span>&#xa0;</span><span>&#xa0;</span>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Şirket, bilgilerinizi saklama konusunda azami özeni göstererek kişisel
      verilerin hukuka aykırı olarak işlenmesini ve bunlara erişilmesini
      önlemek, kişisel verilerin muhafazasını sağlamak amacıyla uygun güvenlik
      düzeyini temin etmeye yönelik gerekli teknik ve idari tedbiri almakla
      yükümlüdür.</span
    ><span>&#xa0;</span>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Şirket, üçüncü taraf iş ortakları başta olmak üzere, yasal yükümlülükler
      veya Şirket sistem işleyişi kapsamında ilgili bilgilere erişime ihtiyaç
      duyan çalışanlara ve iş ortaklarına, bilgilere kısıtlı erişim olanağı
      tanır.</span
    ><span>&#xa0;</span>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Şirket’in ziyaretçilerin, müşterilerin veya potansiyel müşterilerin beyan
      ettiği verilerin doğruluğunu araştırma gibi bir yükümlülüğü olmadığı gibi,
      gerçeğe aykırı beyanlarda da sorumluluk kabul etmemektedir.</span
    ><span>&#xa0;</span><span>&#xa0;</span>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Yine ziyaretçilerimiz, müşteri veya potansiyel müşterilerimiz tarafından
      Şirket’e sağlanan veriler, ancak bizlere gerekli olduğu kadar
      sistemlerimize işlenir ve işlenmiş olsa dahi, bu politika kapsamında
      gerekli olmayan veriler sistemlerimizden kısa süre içinde silinir, yok
      edilir ve/veya anonim hale getirilir.</span
    ><span>&#xa0;</span>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong
      ><span
        >Kişisel Verilerinizin Aktarılması, Aktarılma Amacı ve Aktarılacak Alıcı
        Grupları</span
      ></strong
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Toplanan kişisel verileriniz, bu verilerin depolanması veya bu metinde
      yer alan hukuka uygun amaçlarla kullanıcının bulunduğu ülke ve yargı
      bölgesinden başka dünya çapındaki ülkelere, başka bir ülkede bulunan
      sunuculara ancak açıkça onay vermeniz durumunda aktarılabilir.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Buna göre Şirket, işbu Gizlilik ve Çerez Politikası’nda belirtilen
      faaliyetleri yürütmek amacıyla işleme sürecini alt sözleşme ile bir
      başkasına devredebilir veya yaşadığınız ülke dışındaki ülkelerde bulunan
      üçüncü taraflarla bilgilerinizi paylaşabilir. Bu nedenle kişisel
      bilgileriniz yaşadığınız ülkedekinden farklı gizlilik kurallarına tabi
      olabilir. Ancak yine de, veriler bu metinde yer alan kapsamla sınırlı
      olmak üzere işlenecektir.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Şirket, kişisel bilgileri, hukuki yükümlülüklerini göz önünde tutarak,
      hizmetleri sunmasında yardım sağlayan veya Şirket adına hizmet sağlayan
      tedarikçi şirketler ile paylaşabilir (bilgi güvenliği ve altyapı
      hizmetleri, kurumsal kaynak planlama hizmetleri, ticari ileti elektronik
      kayıt ve veri tabanı hizmetleri). Bu kapsamda kişisel verileriniz, hizmet
      satın alım süreçlerini yürütebilmemiz ve tedarikçilerimizle birlikte
      sizlere daha kaliteli hizmet sağlayabilmemiz için münhasıran yukarıda
      belirtilen amaçlarla aktarılmakta ve işleme tabi tutulmaktadır. Bu servis
      sağlayıcılar sadece hizmetlerin ifası için gerekli olduğu ölçüde bu
      bilgilere erişebilmektedir.</span
    ><span>&#xa0;</span
    ><span>
      Aldığımız idari tedbirler kapsamında da kişisel verilerinizin güvenliğini
      sağlamaya yönelik gerekli önlemleri alıp bu bilgileri korumakla
      yükümlüdürler.</span
    ><span>&#xa0;</span>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Şirket yasalar karşısındaki yükümlülüklerini ifa etmesi amacıyla (suçla
      mücadele ve devlet ve kamu güvenliğinin tehdidi benzeri bilgi verme
      yükümlülüğünün mevcut olduğu durumlarda) kişisel verileri talep etmeye
      yetkili olan kamu kurum ve kuruluşları ile kişisel verileriniz
      paylaşılabilir.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong><span>Üçüncü Taraf Web Siteleri ve Platformlar</span></strong>
  </p>
  <p>
    <span
      >Şirket, zaman zaman Platformlar’a, üçüncü şahıslara ait internet
      sitelerine ilişkin bilgi ve linkleri dahil edebilir. Bu linklere
      tıklayarak diğer internet sitelerine girdiğinizde, söz konusu sitelerinin
      ya da bu sitelerin uygulamaları kontrolümüz altında olmadığı gibi, bu
      gizlilik politikası erişebildiğiniz bu diğer siteler bakımından geçerli
      değildir. Bu tip durumlarda yönlendirildiğiniz üçüncü kişi platformların
      gizlilik politikalarını incelemenizi öneririz.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong><span>KVKK’nın 11. Maddesinde Sayılan Haklarınız</span></strong>
  </p>
  <p>
    <span
      >Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi
      belirtmek ve kişisel verileriniz üzerindeki haklarınızı kullanmak
      amacıyla;</span
    ><span>&#xa0;</span><strong><span>Bilgesin</span></strong
    ><span>&#xa0;</span
    ><span>Platformları’ndan erişebileceğiniz “İletişim Formu”, ve</span
    ><span>&#xa0;</span><strong><span>Şirket</span></strong
    ><span>&#xa0;</span
    ><span
      >resmi e-mail adresi
      <a href="mailto:info@{{ hostName }}">info&#64;{{ hostName }}</a> resmi
      telefon hattı olan</span
    ><span>&#xa0;</span><span>numarası 05333792509</span
    ><strong><span> </span></strong
    ><span
      >üzerinden gerekli değişiklik, güncelleme ve/veya silme gibi işlemleri ve
      ilgili talepleri gerçekleştirebilirsiniz. Belirtilen yöntemlerle
      taleplerinizi bize iletmeniz durumunda, Şirket talebinizin niteliğine göre
      talebi en kısa sürede ve en geç otuz gün içinde ücretsiz olarak
      sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi
      hâlinde, Şirket tarafından Kişisel Verileri Koruma Kurulunca belirlenen
      tarifedeki ücret alınacaktır. Bu kapsamda Kanun’un “ilgili kişinin
      haklarını düzenleyen” 11. maddesi kapsamındaki taleplerinizi</span
    ><span>&#xa0;</span
    ><span
      >(kişisel verilerin işlenip işlenmediği öğrenme, bilgi talep etme,
      aktarılan kişileri bilme, silinmesini isteme vb.), “Veri Sorumlusuna
      Başvuru Usul ve Esasları Hakkında Tebliğe” göre Şirket'e
      iletebilirsiniz.</span
    ><span>&#xa0;</span>
  </p>
  <p>
    <strong><span>Çerezler</span></strong>
  </p>
  <p>
    <span
      >“Cookie” olarak da adlandırılan çerezler, ziyaret ettiğiniz internet
      siteleri tarafından tarayıcılar aracılığıyla bilgisayarınıza (ya da akıllı
      telefon veya tablet gibi diğer cihazlarınıza) kaydedilen ve genelde harf
      ve rakamlardan oluşan çok küçük metin dosyalarıdır. Çerezler,
      ziyaretçilere ilişkin isim, cinsiyet veya adres gibi kişisel verileri
      içermezler.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Çerezler, ziyaret ettiğiniz web sitesini yöneten sunucular tarafından
      oluşturulurlar. Böylelikle ziyaretçi aynı siteyi ziyaret ettiğinde sunucu
      bunu anlayabilir. Çerezler, web sitesi sahiplerine aynı ziyaretçinin
      siteyi yeniden ziyaret ettiğini gösteren kimlik kartlarına
      benzetilebilir.</span
    >
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong><span>Çerezler Nasıl Kullanılmaktadır?</span></strong>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong><span>Şirket</span></strong
    ><span> çerezleri;</span>
  </p>
  <ul>
    <li>
      <span
        >Yaptığınız tercihleri hatırlamak ve web sitesi/mobil sitesi
        kullanımınızı kişiselleştirmek için kullanır. Bu kullanım:</span
      >
      <ul style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt">
        <li style="margin-left: 29pt; padding-left: 7pt">
          <span
            >Web sitesine/mobil sitesine daha sonraki ziyaretlerinizde sizi
            hatırlayan ve tanıyan çerezleri içerir.</span
          >
        </li>
      </ul>
    </li>
    <li>
      <strong><span>Şirket</span></strong
      ><span>
        tarafından işletilen websitesine nereden ve hangi cihazlardan
        bağlandığınız, web sitesi/mobil sitesi üzerinde hangi içeriği
        görüntülediğiniz ve ziyaretinizin süresi gibi web sitesini/mobil
        sitesini nasıl kullandığınızın tespiti de dahil olmak üzere; web
        sitesini/mobil siteyi nasıl kullandığınızı belirlemek için
        kullanır.</span
      >
    </li>
    <li>
      <span
        >İlgi alanlarınıza ve size daha uygun içerik ve reklamları sunmak için,
        diğer bir ifade ile hedeflenmiş reklam/tanıtım amacıyla kullanır.</span
      ><span>&#xa0;</span>
    </li>
    <li>
      <strong
        ><span
          >Site’yi analiz etmek ve Site’nin performansını arttırmak.</span
        ></strong
      ><span>
        Örneğin, Site’yi ziyaret edenlerin sayısının tespit edilmesi ve buna
        göre performans ayarlarının yapılması ya da ziyaretçilerin aradıklarını
        bulmalarının kolaylaştırılması.</span
      >
    </li>
    <li>
      <strong
        ><span
          >Site’nin işlevselliğini arttırmak ve kullanım kolaylığı
          sağlamak.</span
        ></strong
      ><span>
        Örneğin, Site’yi ziyaret eden ziyaretçinin daha sonraki ziyaretinde
        kullanıcı adı bilgisinin ya da arama sorgularının hatırlanması veya Site
        üzerinden üçüncü taraf sosyal medya faaliyetlerine bağlantı
        sağlamak.</span
      >
    </li>
  </ul>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong
      ><span
        >Şirket Üçüncü Taraf Çerezlerini Reklam Ve Yeniden Hedefleme İçin Nasıl
        Kullanmaktadır?</span
      ></strong
    ><span>&#xa0;</span>
  </p>
  <ul>
    <li>
      <strong><span>Şirket</span></strong
      ><span> çerezleri ayrıca; arama motorlarını, </span
      ><strong><span>Şirket </span></strong
      ><span>tarafından işletilen web sitesini, mobil sitesi ve/veya </span
      ><strong><span>Şirket’in</span></strong
      ><span>
        reklam verdiği web sitelerini ziyaret ettiğinizde ilginizi
        çekebileceğini düşündüğü reklamları size sunabilmek için “reklam
        teknolojisini” devreye sokmak amacıyla kullanabilir. Reklam teknolojisi,
        size özel reklamlar sunabilmek için web sitesine/ mobil sitesine ve </span
      ><strong><span>Şirket’in</span></strong
      ><span>
        reklam verdiği web sitelerine/mobil sitesi uygulamalarına yaptığınız
        önceki ziyaretlerle ilgili bilgileri kullanır. Bu reklamları sunarken, </span
      ><strong><span>Şirket’in </span></strong
      ><span
        >sizi tanıyabilmesi amacıyla tarayıcınıza benzersiz bir üçüncü taraf
        çerezi yerleştirilebilir.</span
      >
    </li>
  </ul>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <strong><span>Çerez Yönetimi</span></strong>
  </p>
  <p>
    <span>&#xa0;</span>
  </p>
  <p>
    <span
      >Kullandığınız İnternet tarayıcısı genellikle Çerezler kabul edilmiş
      şekilde açılmaktadır. Ancak dilediğiniz an bu ayarları değiştirebilir ve
      Çerezleri devre dışı bırakabilirsiniz. Eğer Çerezleri devre dışı bırakmaya
      karar verirseniz; Site’de sunduğumuz hizmetlerden işlevsel olarak
      faydalanamayabilirsiniz. İnternet tarayıcınızın çeşidine göre aşağıdaki
      adımları izleyerek, çerezler hakkında bilgi edinip, izin verme veya
      reddetme hakkınızı kullanabilirsiniz:</span
    >
  </p>
  <ul>
    <li>
      <span
        >Google Chrome: Browser’ınızın “adres bölümünde” yer alan, “kilit
        işareti” veya “i” harfini tıklayarak, “Cookie” sekmesinden çerezlere
        izin verebilir veya bloke edebilirsiniz.</span
      >
    </li>
    <li>
      <span
        >İnternet Explorer: Browser’ınızın sağ üst köşesinde yer alan “Tool”
        veya “Araçlar” Bölümünden “Güvenlik” sekmesini tıklayın ve “izin ver”
        veya “izin verme” şeklinde çerez yönetimizi gerçekleştirin.</span
      >
    </li>
    <li>
      <span
        >Mozilla Firefox: Browser’ınızın sağ üst köşesinde yer alan “menüyü aç”
        sekmesini tıklayınız. “Seçenekler” görselini tıklayarak “Gizlilik ve
        Güvenlik” butonunu kullanarak çerez yönetiminizi yapınız.</span
      >
    </li>
    <li>
      <span
        >Diğer browserlar için de (opera, microsoft edge gibi), ilgili
        browser’ın yardım veya destek sayfalarını inceleyebilirsiniz.</span
      >
    </li>
    <li
      style="
        margin-left: 27.6pt;
        text-align: justify;
        padding-left: 8.4pt;
        font-family: serif;
        font-size: 10pt;
      "
    >
      <span
        >Safari: Telefonunuzun “Ayarlar” bölümünden “safari” sekmesini seçip,
        “Gizlilik ve Güvenlik” Bölümünden tüm çerez yönetiminizi
        sağlayabilirsiniz.</span
      >
    </li>
    <li>
      <span
        >Yukarıdaki seçeneklerin yanısıra; tüm çerezler hakkında bilgi sahibi
        olmak ve çerez yönetimi için: </span
      ><strong
        ><span
          ><a
            href="https://www.allaboutcookies.org"
            target="_blank"
            rel="noreferrer"
            >https://www.allaboutcookies.org</a
          ></span
        ></strong
      ><span>, </span
      ><strong
        ><span
          ><a
            href="https://www.youronlinechoices.eu/"
            target="_blank"
            rel="noreferrer"
            >https://www.youronlinechoices.eu/</a
          ></span
        ></strong
      ><span>
        adresini ziyaret edebilirsiniz, veya "Privacy Badger" uygulamasını
        kullanabilirsiniz (</span
      ><strong
        ><span
          ><a
            href="https://www.eff.org/tr/privacybadger"
            target="_blank"
            rel="noreferrer"
            >https://www.eff.org/tr/privacybadger</a
          ></span
        ></strong
      ><span>)</span>
    </li>
  </ul>
  <p>
    <br />
  </p>
  <p>
    <strong><span>ÇEREZ ÇEŞİTLERİ</span></strong>
  </p>
  <table>
    <tr>
      <td>
        <p>
          <strong><span>Oturum Çerezleri</span></strong>
        </p>
      </td>
      <td>
        <p>
          <span
            >Oturum çerezleri ziyaretçilerimizin Site’yi ziyaretleri süresince
            kullanılan, tarayıcı kapatıldıktan sonra silinen geçici
            çerezlerdir.</span
          >
        </p>
        <p>
          <span
            >Bu tür çerezlerin kullanılmasının temel amacı ziyaretiniz süresince
            Site’nin düzgün bir biçimde çalışmasını sağlamaktır.</span
          >
        </p>
      </td>
    </tr>
    <tr>
      <td>
        <p>
          <strong><span>Kalıcı Çerezler</span></strong>
        </p>
      </td>
      <td>
        <p>
          <span
            >Kalıcı çerezler Site’nin işlevselliğini artırmak, ziyaretçilerimize
            daha hızlı ve iyi bir hizmet sunmak amacıyla kullanılan çerez
            türleridir. Bu tür çerezler ziyaretçi tercihlerini hatırlamak için
            kullanılır ve tarayıcılar vasıtasıyla kullanılan cihazda
            depolanır.</span
          >
        </p>
        <p>
          <span
            >Kalıcı çerezlerin bazı türleri Site’yi kullanım amacınız gibi
            hususlar göz önünde bulundurarak sizlere özel öneriler sunulması
            için kullanılabilmektedir.</span
          >
        </p>
        <p>
          <span
            >Kalıcı çerezler sayesinde Site, aynı cihazla tekrardan ziyaret
            etmeniz durumunda, cihazınızda Site tarafından oluşturulmuş bir
            çerez olup olmadığı kontrol edilir ve var ise, sizin siteyi daha
            önce ziyaret ettiğiniz anlaşılır ve size iletilecek içerik bu
            doğrultuda belirlenir ve böylelikle sizlere daha iyi bir hizmet
            sunulur.</span
          >
        </p>
      </td>
    </tr>
    <tr>
      <td>
        <p>
          <strong><span>Teknik Çerezler</span></strong>
        </p>
      </td>
      <td>
        <p>
          <span
            >Teknik çerezler ile Site’nin çalışması sağlanmakta, internet
            sitesinin çalışmayan sayfaları ve alanları tespit
            edilmektedir.</span
          >
        </p>
      </td>
    </tr>
    <tr>
      <td>
        <p>
          <strong><span>Hedefleme/Reklam Çerezleri</span></strong>
        </p>
      </td>
      <td>
        <p>
          <span
            >Site’de kullanıcılara sunulan reklamları özelleştirmek ve zaten
            görüntülenmiş reklamların tekrar gösterilmesini engellemek için
            kullanılan çerez türleridir.</span
          >
        </p>
      </td>
    </tr>
    <tr>
      <td>
        <p>
          <strong><span>Kişiselleştirme Çerezleri</span></strong>
        </p>
      </td>
      <td>
        <p>
          <span
            >Kullanıcıların tercihlerini farklı Site sayfalarını ziyarette de
            hatırlamak için kullanılan çerezlerdir. Örneğin, seçmiş olduğunuz
            dil tercihinizin hatırlanması.</span
          >
        </p>
      </td>
    </tr>
    <tr>
      <td>
        <p>
          <strong><span>Analitik Çerezler</span></strong>
        </p>
      </td>
      <td>
        <p>
          <span
            >Analitik çerezler ile Site’yi ziyaret edenlerin sayıları, Site’de
            görüntülenen sayfaların tespiti, Site ziyaret saatleri, Site
            sayfaları kaydırma hareketleri gibi analitik sonuçların üretimini
            sağlayan çerezlerdir.</span
          >
        </p>
      </td>
    </tr>
  </table>
</div>
